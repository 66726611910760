@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@500;600;700;800&display=swap');
@import "fonts/race-sport/font-race-sport.css";
@import "fonts/poppins/font-poppins.css";
@import "fonts/darker-grotesque/font-darker-grotesque.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "bootstrap/bootstrap-nav.css";
@import "settings/all";
@import "tools/all";
@import "generic/normalize";
@import "vendor/bootstrap/bootstrap-grid";
//@import "vendor/hint/hint";
@import "elements/all";
@import "components/all";
@import "objects/all";
@import "sections/all";
@import "pages/all";
