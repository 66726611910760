.section-worldcup {
    &.page-content {
        padding: 120px 0 0;
        background-color: #1C000A;
    }

    background: url(../../assets/images/worldcup/wc-bg-1.png),
    url(../../assets/images/worldcup/wc-bg-2.png);
    background-repeat: no-repeat,
    no-repeat;
    background-position: top,
    bottom;

    &::before {
        background: url(../../assets/images/worldcup/wc-wapper-left.png) no-repeat;
        background-position: center;
    }

    &::after {
        background: url(../../assets/images/worldcup/wc-wrapper-right.png) no-repeat;
        background-position: center;
    }

    .items-info {
        .info {
            background-color: #000000;
            width: 100%;
            padding: 10px 25px 20px;
            .index {
                font-size: 32px;
            }
        }
    }

    .worldcup-round-header {
        .header-title {
            span {
                font-size: 36px;
                line-height: 41.4px;
            }
            p {
                font-size: 56px;
                line-height: 56px;
            }
        }
    }
}

@media (max-width: 768px) {
    .section-worldcup {
        .worldcup-round-header {
            .header-title {
                span {
                    font-size: 24px;
                    line-height: 18px;
                }
                p {
                    margin-top: 5px;
                    font-size: 31px;
                    line-height: 44px;
                }
            }
            .items-info {
                .info {
                    .index {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}

.text-triple-gradient {
    z-index: 1;
    // color: #64EAF8;
    background-image: -webkit-linear-gradient(270deg, #64EAF8 0%, #FFFFFF 50%, #FF84DD 100%);
    background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(-2px 3px #1749ff);
    text-transform: capitalize;
}

.text-triple-gradient-2 {
    z-index: 1;
    font-size: 32px;
    background-image: linear-gradient(to right bottom, #4cbfcb, #7ac9e0, #a3d4ed, #c7dff5, #e4ebfa, #e7eafd, #ece8ff, #f2e6ff, #efd1fe, #f2baf8, #f7a1ed, #ff84dd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    filter: drop-shadow(-2px 2px #1749ff);
    // text-transform: capitalize;
}

.text-triple-shadow {
    // text-shadow: 0 0 20px rgba(64,0,22,1);
}

.border-gradient-red {
    position: relative;
    background-clip: padding-box;
    border: solid 2px transparent;
    border-radius: 10px;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -2px;
        border-radius: inherit;
        background: linear-gradient(to right bottom, #3d0516, #4e0c20, #60132a, #731b35, #862341);
}
}