.page-content {
  .main-content {
    padding: 0;
  }
}

.page-content {
  padding: 180px 0 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .container {
    padding-right: 20px;
    padding-left: 20px;
  }

  //.section-title {
  //  font-size: 48px;
  //  line-height: 1.125;
  //}

  .brand-image {
    margin: 0 0 30px;

    img {
      max-width: 40vw;
    }
  }

  .section-description-wrap {
    margin: 0;
  }

  .section-description {
    text-transform: none;
    color: #fff;
    font-size: 24px;
    line-height: 1.25;
    width: 870px;
    max-width: 90%;
  }

  .section-content {
    position: relative;
    z-index: 11;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    text-align: center;
  }

  .deposit-page .moonfit-card {
    margin-top: 20px;
  }

  .moonfit-card {
    max-width: 570px;
    margin-top: 54px;

    >.button {
      min-width: 300px;
    }
  }

  .footer-menu {
    margin: 47px 0 0;
  }

  .section-shape-promoting-bg {
    bottom: 10%;
  }

  .section-shape-satellite-1 {
    bottom: 5%;
    left: 0;
    transform: translate(-50%, 0);

    img {
      max-width: 12vw;
    }
  }

  .section-shape-satellite-2 {
    top: 10%;
    right: 0;
    //transform: translate(50%, 0);

    img {
      max-width: 22vw;
    }
  }

  .section-shape-kusama {
    top: 50%;
    left: 10%;

    img {
      max-width: 10vw;
    }
  }

  .section-shape-polkadot {
    bottom: 10%;
    right: 9%;

    img {
      max-width: 12vw;
    }
  }

  .section-shape-beast-nft {
    left: 3%;
    bottom: 15%;

    img {
      scale: 0.5;
      max-width: 12vw;
    }
  }

  .section-shape-beauty-nft {
    right: 3%;
    top: 6%;

    img {
      scale: 0.5;
      max-width: 12vw;
    }
  }

  .section-shape-beauty-2-nft {
    right: 10%;
    bottom: 20%;

    img {
      scale: 0.6;
      max-width: 12vw;
    }
  }

  .section-shape-beast {
    left: 5.7%;
    top: 28%;

    img {
      max-width: 8vw;
    }
  }

  .section-shape-beast-2 {
    left: 5.7%;
    bottom: 10%;

    img {
      max-width: 6vw;
      transform: rotate(-20deg);
    }
  }

  .section-shape-beauty {
    right: 9.1%;
    top: 14%;

    img {
      max-width: 12vw;
    }
  }

  .section-shape-beauty-card-1 {
    top: 12%;
    right: 15%;

    img {
      max-width: 12vw;
    }
  }

  .section-shape-beauty-card-2 {
    top: 9%;
    left: 15%;

    img {
      max-width: 12vw;
    }
  }

  .section-shape-beauty-card-3 {
    top: 50%;
    right: 18%;

    img {
      scale: 0.7;
      transform: rotate(10deg);
      max-width: 12vw;
    }
  }

  .section-shape-beast-card-1 {
    top: 45%;
    left: 12%;

    img {
      transform: rotate(-20deg);
      max-width: 12vw;
    }
  }

  .section-shape-mint-pass-1 {
    top: 28%;
    left: 7%;

    img {
      scale: 0.6;
      opacity: 0.6;
      max-width: 12vw;
    }
  }

  .section-shape-mint-pass-2 {
    top: 28%;
    right: 10%;
    z-index: -1;

    img {
      max-width: 12vw;
    }
  }

  .section-shape-beauty-2 {
    right: 8%;
    bottom: 30%;

    img {
      max-width: 8vw;
    }
  }

  .shape-token-mfr-nft-1 {
    left: 25%;
    bottom: 22%;

    img {
      transform: rotate(25.79deg);
    }
  }

  .shape-token-mfr-nft-2 {
    right: 6.6%;
    bottom: 40%;
  }

  .shape-token-mfg-nft-2 {
    left: 30%;
    top: 14%;
    transform: rotate(25.79deg);
    filter: blur(1px);
  }

  .shape-token-mfr-1 {
    left: 23%;
    bottom: 20%;

    img {
      transform: rotate(25.79deg);
    }
  }

  .shape-token-mfr-2 {
    right: 26.6%;
    top: 46.8%;
  }

  .shape-token-mfg-1 {
    right: 20%;
    top: 14%;
  }

  .shape-token-mfg-2 {
    left: 45%;
    top: 14%;
    transform: rotate(25.79deg);
    filter: blur(1px);
  }

  .page-container {
    width: 65%;
    margin: 0 auto;
    z-index: 2;

    &.not-limit-width {
      width: unset !important;
    }
  }

  &.section-worldcup {
    .section-shape-beast-nft {
      left: 2%;
      bottom: 26%;

      img {
        scale: 0.6;
        max-width: 12vw;
      }
    }

    .section-shape-beauty-nft {
      right: 5%;
      top: 26%;

      img {
        scale: 0.8;
        max-width: 12vw;
      }
    }

    .section-shape-beauty-2-nft {
      right: 8%;
      bottom: 19%;

      img {
        scale: 0.7;
        max-width: 12vw;
      }
    }

    .shape-token-mfr-nft-1 {
      left: 15%;
      bottom: 19%;

      img {
        transform: rotate(25.79deg);
      }
    }

    .shape-token-mfr-nft-2 {
      top: 24%;
      left: 18%;
    }

    .shape-token-mfg-nft-2 {
      right: 20%;
      top: 23%;
      left: unset;
      transform: rotate(25.79deg);
      filter: blur(1px);
    }

    .shape-token-mfg-nft-3 {
      left: 25%;
      top: 10%;
    }
  }

}

@media screen and (max-width: 1600px) {
  .page-content {
    padding-top: 130px;

    .page-container {
      width: 85%;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .page-content {
    &.section-worldcup {

      .section-shape-beauty-nft,
      .section-shape-beauty-2-nft,
      .shape-token-mfr-nft-1,
      .shape-token-mfr-nft-2,
      .shape-token-mfg-nft-2,
      .shape-token-mfg-nft-3 {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .page-content {
    padding-top: 110px;

    .page-container {
      width: 100%;
      margin: 0 auto;
    }
  }
}


//
//@media (min-width: 992px) {
//}
//
//@include media-breakpoint-down(lg) {
//  .page-mint-pass {
//    .section-title {
//      font-size: 50px;
//    }
//  }
//}
//
//@include media-breakpoint-down(md) {
//  .page-mint-pass {
//    .section-title {
//      font-size: 44px;
//    }
//
//    .section-description {
//      font-size: 22px;
//    }
//  }
//}
//
//@include media-breakpoint-down(sm) {
//  .page-mint-pass {
//    .section-title {
//      font-size: 38px;
//    }
//
//    .connect-image {
//      margin: 0 0 30px;
//
//      img {
//        max-width: 56vw;
//      }
//    }
//
//    .section-description {
//      font-size: 20px;
//    }
//
//    .moonfit-card {
//      margin-top: 35px;
//    }
//  }
//}
//
//@include media-breakpoint-down(xs) {
//  .page-mint-pass {
//    .section-title {
//      font-size: 32px;
//    }
//
//    .section-description {
//      font-size: 18px;
//    }
//  }
//}
.custom-progress {
  .ant-progress-inner {
    background-color: #462368;
  }
}

.ant-pagination.ant-pagination-mini {
  .ant-pagination-item {
    margin: 0 2px !important;
  }

  .ant-pagination-prev {
    margin-right: 7px !important;
  }

  .ant-pagination-next {
    margin-left: 7px !important;
  }
}

.wrapper-modal {
  background: rgb(63 26 26 / 0.9);

  .list-team {
    height: 600px;
    overflow: auto;
    margin-right: -25px;
    padding-right: 25px;

    .team-image {
      cursor: pointer;

      img {
        padding: 8px;
      }

      &.selected {
        position: relative;
        border: 0px solid transparent;
        background-clip: padding-box;
        padding: 2px;
        background-image: linear-gradient(to right bottom, #4cbfcb, #e4007b);
        border-radius: 5px;

        .team-image-border {
          width: 100%;
          height: 100%;
          background-color: #000000;
          border-radius: 4px;
        }
      }
    }

    span {
      cursor: pointer;
    }
  }

  @media (max-width: 996px) {
    .list-team {
      height: 500px;
    }
  }

  @media (min-width: 768px) {
    .ant-modal-content {
      width: 600px !important;
    }
  }

  @media (max-width: 768px) {
    .ant-modal-content {
      .ant-modal-header {
        .ant-modal-title {
          font-size: 24px !important;
        }
      }
    }

    .list-team {
      height: 400px;
      grid-template-columns: repeat(2, minmax(0, 1fr)) !important;

      .team-name {
        font-size: 12px !important;
      }
    }
  }

  @media (max-width: 576px) {
    .list-team {
      height: 300px;
    }
  }

  .ant-modal {
    top: 50px !important;
  }

  .ant-modal-content {
    background-color: #000000;
    color: #ffffff;
    box-shadow: 0 0 40px 15px rgb(102 66 79 / 50%);
    -webkit-box-shadow: 0 0 40px 15px rgb(102 66 79 / 50%);
    -moz-box-shadow: 0 0 40px 15px rgba(102, 66, 79, .5);

    position: relative;
    background-clip: padding-box;
    border: solid 2px transparent;
    border-radius: 20px;
    padding: 0 5px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -3px;
      border-radius: inherit;
      background: linear-gradient(to right bottom, #3d0516, #4e0c20, #60132a, #731b35, #862341);
    }

    .ant-modal-close {
      position: absolute;
      width: 32px;
      height: 32px;
      line-height: 32px;
      top: -50px;
      color: #ffffff;
      right: 48.5%;
      border-radius: 10px;
      border: none;

      .ant-modal-close-x {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
    }

    .ant-modal-header {
      text-align: center;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #000000;
      border-bottom: 1px solid #000000;

      .ant-modal-title {
        font-family: "Race Sport", serif;
        color: #ffffff;
        font-weight: bold;
        font-size: 36px;
        padding-top: 25px;
      }
    }

    .modal-body-row {
      padding: 16px 22px;
      margin: 15px 0;
      background-color: #000000;
      border-radius: 7px;

      .modal-body-row-title {
        color: #a8adc3;
        font-size: 16px;
      }
    }

    .ant-modal-footer {
      border-top: 1px solid #151a30;
      padding: 15px 20px;

      .flex {
        display: flex;

        .w-1\/2 {
          width: 50%;
        }

        .button {
          color: #fff;
          border-radius: 5px;
          line-height: 22px;
          min-height: 44px;
          font-size: 16px;
          font-weight: 800;
          letter-spacing: 0;
          cursor: pointer;
          transition: 0.2s ease-in-out;

          &:hover {
            transform: translateY(-2px);
          }

          &+button {
            margin-left: 15px;
          }
        }

        .button-dark {
          background: hsla(0, 0%, 100%, 0.2);
          border: 2px solid hsla(0, 0%, 100%, 0.2);
          padding: 0 var(--button-hoz-padding);
        }

        .button-secondary {
          border: #e4007b;
          background-color: #e4007b;
        }
      }

      .w-full {
        width: 100%;
      }
    }

    .button {
      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: #141414 #2c2c2c;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #2c2c2c;
}

*::-webkit-scrollbar-thumb {
  background-color: #141414;
  border-radius: 8px;
  border: 2px solid #1c1c1c;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.form-mint-footer {
  margin: 16px -22px -16px;
  padding: 20px;
  border-radius: 7px;
  background: #29000F;
}

.deposit-page  {
  .user-info {
    display: flex;

    &.is-loading {
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    .avatar {
      margin-right: 10px;
    }

    p {
      text-transform: none;
    }
  }

  .btn-deposit {
    display: block;
    min-height: 30px;
    font-size: 14px;
    padding: 5px 20px !important;
    min-width: initial !important;
  }
}