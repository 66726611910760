/* Mobile Menu */
.navbar-toggler {
	//margin: 0 0 0 16px;
	display: block;
	position: relative;
	width: 28px;
	height: 24px;
	color: #ffffff;
	border-color: transparent;

	&:hover,
	&:focus {
		box-shadow: none;
		background: none;
		outline: none;
		border-color: transparent;
		color: #ffffff;
	}

	.navbar-toggler-icon {
		display: none;
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -1px;
		width: 100%;
		height: 2px;
		background: currentColor;
	}

	&:before {
		position: absolute;
		top: -1px;
		left: 2px;
		width: 32px;
		height: 2px;
		background: currentColor;
		content: '';
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		-webkit-transform-origin: 0 0;
		-ms-transform-origin: 0 0;
		transform-origin: 0 0;
		-webkit-transition: all .3s;
		transition: all .3s;
	}

	&:after {
		position: absolute;
		bottom: -1px;
		left: 2px;
		width: 32px;
		height: 2px;
		background: currentColor;
		content: '';
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		transform: rotate(-45deg);
		-webkit-transform-origin: 0 100%;
		-ms-transform-origin: 0 100%;
		transform-origin: 0 100%;
		-webkit-transition: all .3s;
		transition: all .3s;
	}

	&.collapsed .navbar-toggler-icon {
		display: block;
	}

	&.collapsed:before,
	&.collapsed:after {
		left: 0;
		width: 100%;
		height: 2px;
		-webkit-transform: rotate(0);
		-ms-transform: rotate(0);
		transform: rotate(0);
	}

	&.collapsed:before {
		bottom: 0;
	}

	&.collapsed:after {
		bottom: 0;
	}
}

.mobile-menu {
	position: absolute;
	top: 100%;
	left: 15px;
	right: 15px;
	background-color: $body-bg-color;
	border: 2px solid $primary-color;
	box-shadow: 0 4px 34px rgba(102,225,182,.3);
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;

	ul {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 13px 0;
		margin: 0 -10px;

		li {
			-webkit-box-flex: 0;
			-webkit-flex: 0 0 100%;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
			padding: 0 10px;

			a {
				font-size: 16px;
				font-weight: 500;
				color: $body-color;

				&:hover,
				&:focus {
					color: $primary-color;
				}
			}
		}
	}
}








