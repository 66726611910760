.page-nft-sale {
  --border-radius: 5px;
  padding: 10px 10px 0;
  z-index: 1;

  .count-minted {
    min-height: 30px;
  }

  .nft-stages {
    .nft-timeline {
      .nft-timeline-ul {
        //border-left-color: #F0489F;
        //border-left-width: 1px;
        //border-left-style: dashed;

        .ant-timeline-item-tail {
          border-left: 1px dashed #e4007b;
        }

        .is-sold-out .ant-timeline-item-tail {
          border-left-style: solid;
        }

        .ant-timeline-item-head-custom {
          top: 0;
          padding: 0;
        }

        .ant-timeline-item-content {
          padding-top: 10px;
        }

        .marker {
          background: #e4007b;
          box-shadow: 0px 0px 14px #e4007b;

          //&:after {
          //  background: radial-gradient(50% 50% at 50% 50%, rgba(161, 107, 216, 0) 0%, rgba(161, 107, 216, 0.261) 100%);
          //}
        }

        .sale-title {
          font-size: 20px;
        }
      }
    }
  }

  .mp-item {
    position: relative;
    //cursor: pointer;

    .square-img-container {
      width: 100%;
      border-radius: var(--border-radius);
      background-color: #120838;
      border: 2px solid transparent;

      img {
        border-radius: var(--border-radius);
        padding: 3px;
        background-color: #120838;
      }
    }

    &.mp-used {
      border-radius: var(--border-radius);
      position: relative;
      overflow: hidden;

      &.is-out-of-slot {
        cursor: not-allowed;

        > div {
          opacity: 0.4;
        }
      }
    }

    .used-text {
      position: absolute;
      display: inline-block;
      border-radius: 5px;
      left: 5px;
      top: 7px;
      color: white;
      z-index: 2;
      margin: 4px;
      background-color: #5d5fef;
      text-transform: uppercase;
      padding: 2px 9px;
      font-size: 16px;
    }

    .mp-selected {
      position: relative;
      background-clip: padding-box;
      border: 2px solid transparent;
      background-color: #191a28;
      border-radius: var(--border-radius);
      z-index: 1;

      &:before {
        content: "";
        position: absolute;
        inset: 0;
        z-index: -1;
        margin: -2px;
        background-image: linear-gradient(to right top, #4ccbc9, #e4007b);
        border-radius: inherit;
        box-shadow: 0 4px 30px rgba(228, 0, 123, 0.5);
      }
    }

    .selected-container {
      position: absolute;
      top: 0;
      right: 0;
      background: #e4007b;
      padding: 3px 4px;
      border-bottom-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }
  }

  .nft-item {
    justify-content: flex-end;

    .ant-image-img {
      border-radius: var(--border-radius);
    }

    .attributes {
      width: 100%;
      margin-top: 10px;
      background: rgb(161 107 216 / 15%);
      border-radius: 5px;
      padding: 10px 0 5px;

      &:after {
        content: "";
        clear: both;
      }
    }

    .nft-attribute {
      float: left;
      width: 25%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      text-align: center;

      &:after {
        content: "";
        position: absolute;
        border-right: 1px solid rgb(144 130 157 / 20%);
        height: 75%;
        right: 0;
      }

      &:last-child:after {
        border-right: none;
      }

      // .attribute-value {
      //   font-size: 14px;
      // }
    }

    .nft-item-image {
      position: relative;
      width: 100%;

      .ant-image-mask {
        border-radius: 0;
      }
    }

    .nft-item-rarity {
      position: absolute;
      left: 4px;
      bottom: 4px;
      border-radius: 5px;
      font-weight: 700;
      font-size: 14px;
      display: block;
      padding: 1px 10px 5px 10px;
      text-transform: none;

      background: #efaa5d;

      &.uncommon {
        background: #48c8f0;
      }
    }

    .nft-gender {
      min-width: 100px;
    }

    .nft-number-name {
      min-width: 100px;
    }

    .nft-gender-minting {
      width: 80px;
      margin: 0 auto;
      margin-bottom: 3px;
    }

    .nft-number-name-minting {
      width: 60px;
      margin: 0 auto;
    }
  }
}

.page-nft-sale {
  .ant-pagination {
    margin-top: 30px;
    color: #a8adc3;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-transform: none;
  }

  .ant-pagination-disabled,
  .ant-pagination-disabled:hover {
    opacity: 0.4;
  }

  .ant-pagination-item-link-icon {
    opacity: 0 !important;
  }

  .ant-pagination-item-ellipsis {
    font-size: 8px;
    opacity: 1 !important;
  }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-item-ellipsis {
    color: #a8adc3 !important;
  }

  .ant-pagination-item {
    border: none;
    background-color: transparent;

    a {
      color: #a8adc3;
      font-weight: 700;
    }
  }

  .ant-pagination-item-active {
    background-color: #4ccbc9;
    border-radius: 50%;

    a {
      color: #020722;
      font-weight: 700;
    }
  }
}

@media screen and (min-width: 1024px) {
  .page-nft-sale {
    .nft-item {
      img {
        border-radius: var(--border-radius);
      }

      &:last-child:nth-child(3n - 1) {
        grid-column-end: 0;
      }

      &:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: 4;
      }

      &:last-child:nth-child(3n - 2) {
        grid-column-end: 5;
      }
    }

    .mp-item {
      &:last-child:nth-child(3n - 1) {
        grid-column-end: 0;
      }

      &:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: 4;
      }

      &:last-child:nth-child(3n - 2) {
        grid-column-end: 5;
      }
    }
  }
}

.mp-verify {
  &__title {
    svg {
      display: inline-block;
      margin-left: 5px;
      margin-top: -2px;
      cursor: pointer;
    }
  }

  &__header {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 13px;
    text-transform: uppercase;
    color: #a8adc3;
  }

  &__body {
    background: #2a1143;
    border-radius: 7px;
    padding: 14px 22px;
  }

  &__info {
    color: #90829d;
    text-transform: none;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    line-height: 20px;
    margin: 10px 0 15px;
  }

  &__input {
    background-color: #1c0532 !important;
    color: #a8adc3 !important;
    border: none !important;
    outline: none !important;
    border-radius: 10px 0 0 10px !important;
    height: 50px !important;

    &::placeholder {
      color: rgb(168 173 195 / 71%);
    }
  }

  &__loading {
    display: inline-block;
    margin-right: 5px;
  }

  &__btn {
    height: 50px !important;
    font-weight: 800;
    font-size: 18px;
    color: white !important;
    background-color: #e4007b !important;
    border: none !important;
    outline: none !important;
    border-radius: 5px !important;
    min-width: 125px;
    text-align: center;

    &:before {
      display: none !important;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:hover {
      transform: none;
    }
  }

  &__msg {
    margin-top: 8px;
    text-transform: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #efaa5d;

    svg {
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    &.warning {
      color: #efaa5d;
    }

    &.success {
      color: #a5d990;
    }

    &.error {
      color: #ef2763;
    }
  }
}

@media screen and (max-width: 1600px) {
  .page-nft-sale {
    min-width: 700px;
  }
}

@media screen and (max-width: 800px) {
  .page-nft-sale {
    min-width: 95%;
  }
}

@media screen and (max-width: 600px) {
  .page-nft-sale {
    min-width: 100%;

    .container {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .worldcup-card-inner {
    .form-mint {
      &__team-select {
        span {
          font-size: 14px;
        }

        a {
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }
}

.worldcup-card-inner {
  .form-mint {
    &__warp-input {
      background: #160008 !important;
    }

    &__team-select {
      span {
        font-size: 16px;
      }

      a {
        font-size: 18px;
        cursor: pointer;
      }

      .team-select-detail {
        span {
          font-size: 18px;
        }

        img {
          max-width: 300px;
          cursor: pointer;
        }
      }

      @media (max-width: 600px) {
        .team-select-detail {
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.form-mint {
  &__fee {
    &-label {
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
      color: #a8adc3;
    }
  }

  &__warp-input {
    display: flex;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    width: 115px;
    height: 50px;
    user-select: none;
    background: #1c0532;
    border-radius: 5px;
  }

  &__input-icon {
    cursor: pointer;
  }

  &__input-value {
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;

    input {
      text-align: center;
      background: transparent !important;
      border: none !important;
      color: #fff !important;
      outline: none !important;
      max-width: 50px;
      padding-left: 0 !important;
      padding-right: 0 !important;
      -moz-appearance: textfield;

      &:hover,
      &:focus {
        background: transparent !important;
        border: none !important;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .form-mint {
    flex-wrap: wrap;
    justify-content: space-between;

    &__wrap-btn {
      margin-top: 10px;
      flex-basis: 100%;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
  }
}

.story-section {
  text-transform: initial;
  margin-top: 50px;
  // .moonfit-description, .youtube-video {
  //   height: 100%;
  // }

  .youtube-video {
    width: 100%;

    img {
      border-radius: 10px;
    }
  }

  .what-is-moonfit {
    .description-title {
      font-size: 36px;
    }

    p {
      font-size: 22px;
      line-height: 32px;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
  }

  .list-store {
    img {
      margin: 0 10px;
      width: 164px;
      height: 48px;
    }
  }
}

@media (max-width: 1200px) {
  .story-section {
    .moonfit-description {
      margin-top: 30px;
    }
  }
}

@media (max-width: 768px) {
  .story-section {
    .moonfit-description,
    .youtube-video {
      height: 90%;
    }
  }

  .list-store {
    img {
      height: 44px;
      width: 144px;
    }
  }
}

@media (max-width: 576px) {
  .list-store {
    img {
      width: 128px !important;
      height: 40px !important;
    }
  }
}

.utilities-section {
  padding-top: 150px;
  text-transform: initial;
  // background: url(../../assets/images/promoting-bg.jpg) no-repeat;
  // background-size: contain;

  .grid {
    margin-top: 50px;

    .grid-item {
      // word-break: break-all;
      transition: 0.2s ease-in-out;

      .block-game-feature {
        padding: 10px;
        height: 100%;
        background-color: #020722;
        border: 2px solid transparent;
        background-clip: padding-box;
        border-radius: 10px;
        min-width: 200px;
        position: relative;
        cursor: pointer;
        background-image: url("../../assets/images/utilities/feature-frame-bg.png");
        background-size: cover;

        .block-game-feature-icon {
          margin: 0 0 24px;
        }

        .block-game-feature-title {
          font-size: 20px;
          line-height: 26px;
        }

        .block-game-feature-description {
          font-size: 18px;
          line-height: 28px;
          color: #a8adc3;
          margin: 9px 0;
        }

        &::before {
          content: "";
          position: absolute;
          inset: 0;
          z-index: -1;
          margin: -2px;
          border-radius: inherit;
          background-color: #020722;
          background-image: linear-gradient(to right bottom, #4cbfcb, #e4007b);
          opacity: 0.7;
        }
      }

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 4px 50px rgb(161 7 216 / 50%);

        .block-game-feature-title {
          color: #4ccbc9;
        }
      }
    }
  }
}

.section-web3-nft {
  text-transform: initial;

  .shape-promoting-01 {
    top: 12%;
    left: 7%;
    max-width: 11.5%;
  }

  .shape-promoting-02 {
    bottom: 40%;
    left: 16%;
    max-width: 11.5%;
  }

  .shape-promoting-03 {
    bottom: 28%;
    right: 12%;
    max-width: 11.5%;
  }

  .shape-promoting-04 {
    bottom: 0;
    right: 36%;
    max-width: 12.2%;
  }

  .block-heading-frame {
    position: absolute;
    background: linear-gradient(
      45deg,
      #4ccbc9,
      rgba(43, 54, 28, 0) 70%,
      rgba(43, 54, 28, 0) 82%,
      #4ccbc9
    );
    background: -webkit-linear-gradient(
      135deg,
      #4ccbc9,
      rgba(43, 54, 28, 0) 70%,
      rgba(43, 54, 28, 0) 82%,
      #4ccbc9
    );
    box-shadow: 0 4px 10px rgb(37 240 235 / 40%);
    padding: 27px 22px 20px;
    text-align: left;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 2px;
      bottom: 2px;
      right: 2px;
      left: 2px;
      border-radius: inherit;
      background: #020722;
      z-index: 1;
    }

    .content {
      position: relative;
      z-index: 2;

      .title {
        color: #4ccbc9;
        font-size: 16px;
        font-weight: 400;
      }

      .description {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  svg {
    position: absolute;
  }
}

@media (max-width: 1199px) {
  .section-web3-nft {
    .section-header {
      margin: 30px 0 20px !important;
    }

    .image-group {
      min-height: 0;
    }

    .main-image {
      max-width: 60%;
      margin: 0 0 40px;
    }

    .block-heading-frame {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      transform: none;
      width: 100%;
      margin: 0 0 30px;

      > svg {
        display: none;
      }
    }
  }
}

@media (min-width: 1200px) {
  .section-web3-nft {
    padding: 142px 0 0;

    .section-header {
      margin: 0 0 50px;
    }

    .section-header-inner {
      max-width: 874px;
    }

    .section-description {
      max-width: 660px;
      margin: 0 auto;
    }

    .block-heading-frame {
      width: 300px;
    }

    .image-group {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 520px;
    }

    .main-image {
      max-width: 53%;
    }

    .block-heading-01 {
      left: -80px;
      bottom: 50%;
      transform: translateY(-32px);

      svg {
        transform: translateX(-2px);
        top: 50%;
        left: 100%;
      }
    }

    .block-heading-02 {
      left: -65px;
      top: 50%;
      transform: translateY(32px);

      svg {
        transform: translateX(-2px);
        bottom: 50%;
        left: 100%;
      }
    }

    .block-heading-03 {
      right: -120px;
      bottom: 50%;
      transform: translateY(-102px);

      svg {
        transform: translateX(2px);
        top: 50%;
        right: 100%;
      }
    }

    .block-heading-04 {
      right: -80px;
      top: 50%;
      transform: translateY(-50%);

      svg {
        transform: translateX(2px);
        top: 50%;
        right: 100%;
      }
    }

    .block-heading-05 {
      right: -120px;
      top: 50%;
      transform: translateY(102px);

      svg {
        transform: translateX(2px);
        bottom: 50%;
        right: 100%;
      }
    }
  }
}

.block-text-white {
  display: block;
  color: #fff;
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  text-transform: initial;
}

.purchase-moonbest {
  .card-body-separator {
    margin: 30px 0 20px !important;
  }
  .tabs {
    margin: auto;
    padding: 5px;
    border-radius: 10px;
    border: 2px solid #351c4f;

    .tab {
      text-align: center;
      color: #a8adc3;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      padding: 5px 15px;
      cursor: pointer;
      font-family: "Poppins", sans-serif;

      &.active {
        color: #4ccbc9;
        border-radius: 7px;
        background-color: #351c4f;
        box-shadow: 0px 10px 74px rgba(0, 0, 0, 0.49);
        transition: 0.3s linear ease-in-out;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .packs {
    .pack {
      position: relative;
      border-radius: 10px;
      background: #351c4f;
      cursor: pointer;
      padding: 16px 15px 16px 10px;
      border: 0px solid transparent;
      position: relative;
      background-clip: padding-box !important;

      .left {
        min-width: 200px;
      }

      .right {
        img {
          width: 24px;
          height: 24px;
          margin-top: 5px;
          margin-right: 5px;
        }
        span {
          line-height: 27px;
        }
      }

      .sub-label {
        color: #a8adc3;
        font-weight: 600;
        line-height: 18px;
      }

      .badge-recommend {
        position: absolute;
        top: 4px;
        right: -12px;
        background-color: #e4007b;
        padding: 3px 14px;
        border-radius: 3px;
        display: flex;
        align-items: center;
      }

      &.active {
        padding: 3px;
        background-image: linear-gradient(to right bottom, #4cbfcb, #e4007b);
        box-shadow: 0 4px 50px rgb(161 107 216 / 50%);
        .badge-recommend {
          top: 6px;
        }
        .pack-border {
          content: "";
          width: 100%;
          background-size: cover !important;
          background: url(./../../assets/images/active-pack-bg.png) no-repeat  center;
          padding: 24px 12px 24px 8px;
          background-color: #250e3a;
          border-radius: 8px;
          &:before {
            width: 100%;
            height: 100%;
          }
        }

        .race-sport-font {
          color: #4ccbc9;
        }

        .sub-label {
          color: #fff;
        }
      }
      // &:hover {
      //   opacity: 0.8;
      // }
    }

    .num-with-3 {
      display: inline-block;
      min-width: 35px;
      text-align: right;
    }

    .num-with-4 {
      display: inline-block;
      min-width: 50px;
      text-align: right;
    }
  }

  .unlock-mintpass {
   .left {
    .fee-info {
      font-weight: 600;
      line-height: 21.7px;
    }
    .fee-amount {
      font-weight: 400;
      line-height: 26px;
    }
   }
   .right {
    .button {
      padding-top: 8px;
      padding-bottom: 8px;
      font-weight: 800;
      line-height: 24.41px;
      font-size: 18px;
    }
   }  
  }

  .dot-flashing {
    display: inline-block;
    margin: 0 18px;
  }
}

@media (max-width: 768px) {
  .purchase-moonbest {
    .packs {
      .pack {
        .left {
          min-width: auto;
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .purchase-moonbest {
    .packs {
      .pack {
        .left {
          .pack-icon {
            width: 36px;
          }
          .pack-label {
            p {
              font-size: 11px;
            }
          }
        }
        .right {
          img {
            width: 20px;
          }
          span {
            font-size: 18px;
          }
        }
        .badge-recommend {
          padding: 0 8px;
        }
      }
    }
    .unlock-mintpass {
      .left {
        .fee-amount {
          font-size: 16px;
        }
      }
      .right {
        .button {
          padding: 0;
          font-size: 14px;
          line-height: 16px;
          min-width: 120px;
        }
      }
    }
  }
}


/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #ffffff;
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

.ant-tooltip-inner {
  color: #fff;
  background-color: #a16bd8;
  text-align: center;
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  width: 90%;
}

.ant-tooltip-arrow .ant-tooltip-arrow-content {
  border-color: #a16bd8;
  background-color: #a16bd8;
  --antd-arrow-background-color: none;
}

.minted-nft {
  .left, .right {
    font-weight: 600;
    font-size: 16px;
    line-height: 21.7px;
  }
}