.header {
  @extend %NormalTransition;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  //background-color: #020722;

  .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    //width: 800px;
  }
}

.header-pinned {
  background: #000315;
}

.header.header-pinned .header-inner {
  padding: 10px 0;
}

.site-branding img {
  width: 169px;
}

.header-left,
.header-right {
  flex-shrink: 0;
}

.header-left {
  width: 273px;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .auth-buttons {
    //display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.header-center {
  flex-grow: 1;
}

.primary-menu {
  display: none;

  ul {
    margin: 0;
  }

  .nav-item {
    margin: 0;

    &.active-item,
    &:hover {
      > .nav-link {
        color: $primary-color;

        .nav-text:after {
          width: 100%;
          left: 0;
          right: auto;
        }
      }
    }
  }

  .nav-link {
    position: relative;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    padding: 10px 20px;
    color: #fff;

    .nav-text {
      position: relative;
      display: block;

      &:after {
        content: '';
        width: 0;
        height: 2px;
        position: absolute;
        left: auto;
        right: 0;
        bottom: 0;
        transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
        background: $primary-color;
      }
    }
  }
}

//.primary-menu {
//	display: none;
//}

.navbar-toggler {
  min-height: 0;
}

@include media-breakpoint-down(md) {
  .site-branding {
    img {
      width: 150px;
    }
  }
}

@include media-breakpoint-down(xs) {
  .header-right .header-button {
    display: none;
  }
}

@media only screen and (min-width: 1500px) {
  .primary-menu {
    display: block;

    .nav {
      justify-content: center;
    }
  }

  .navbar-toggler {
    display: none;
  }
}
