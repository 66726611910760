.section {
  position: relative;
}

.svg-symbols {
  /*visibility: hidden;*/
  width: 0;
  height: 0;
  position: fixed;
  top: -9999px;
  left: -9999px
}

.main-content {
  padding: 217px 0 0;
  overflow: hidden;
}

.section-header {
  position: relative;
  z-index: 1;
  text-align: center;
  margin: 0 0 48px;

  .button-group {
    justify-content: center;
  }
}

.section-header-inner {
  margin: 0 auto;
  max-width: 685px;
}

.section-title {
  font-size: 36px;
  line-height: 1.14;
  margin: 0;
}

.section-title-steps {
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    margin-bottom: -0.33em;
  }
}

.section-title-step {
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  background: radial-gradient(50% 50% at 50% 50%, rgba(161, 107, 216, 0) 0%, rgba(161, 107, 216, 0.261) 100%);
  border-radius: 50%;
  line-height: 1;
  margin: 0 13px 0 0;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 1.275em;
    height: 1.275em;
    background: $secondary-color;
  }

  > span {
    position: relative;
    z-index: 1;
    margin-bottom: -0.33em;
  }
}

.section-title-steps-top {
  display: block;

  .section-title-step {
    margin: 0 auto 21px;
  }
}

.section-description-wrap {
  margin: 16px 0 0;
}

.section-description {
  font-size: 18px;
  line-height: 26px;

  p {
    margin-bottom: 21px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    @extend %LinkTransition02;
    color: $primary-color;
  }
}

.section-footer-description {
  margin: 48px 0 0;
  text-align: center;

  .section-description {
    max-width: 670px;
    margin: 0 auto;
  }

  a {
    color: $primary-color;
  }
}

.section-btn-callout {
  margin-top: 45px;
}

.section-header-beside {
  text-align: start;
  margin: 0;

  .section-header-inner {
    max-width: 470px;
    margin: 0;
  }

  .button-group {
    justify-content: flex-start;
  }

  .section-description-wrap {
    margin: 20px 0 0;
  }

  .section-description {
    font-size: 20px;
    line-height: 30px;
  }

  .section-title-steps {
    justify-content: flex-start;
  }
}

.section-header-small {
  .section-title {
    font-size: 32px;
    line-height: 1.375;
  }

  .section-description {
    font-size: 18px;
    line-height: 26px;
  }
}

.section-header-big {
  .section-title {
    font-size: 24px;
  }

  .section-sub-title {
    font-size: 40px;
    line-height: 1.5;
    color: $primary-color;
    margin: 20px 0 0;
  }

  .section-header-inner {
    max-width: 900px;
  }

  .section-description-wrap {
    margin: 20px 0 0;
  }

  .section-description {
    font-size: 20px;
    line-height: 30px;
    max-width: 730px;
    margin: 0 auto;
  }
}

//.moonfit-card {
//  margin-top: 39px;
//}

.section-shapes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
}

.section-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.section-shape {
  position: absolute;
  user-select: none;
  pointer-events: none;
}

.shape-radial-circle {
  width: var(--size, 800px);
  max-width: 50vw;
  border-radius: 50%;
  background: radial-gradient(circle, #371655 0%, rgba(2, 7, 34, 0.1) 70%, rgba(2, 7, 34, 0) 90%);
  //background: radial-gradient(circle, #371655 0%, rgba(2, 7, 34, 0.5) 30%,rgba(2, 7, 34, 0.5) 80%, rgba(2, 7, 34, 0) 100%);
  //background: radial-gradient(circle, #371654 0%, rgba(2, 7, 34, 1) 10%,rgba(2, 7, 34, 1) 90%);

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

@keyframes move-vertical {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(0, 10px);
  }
  50% {
    transform: translate(0, 5px);
  }
  75% {
    transform: translate(0, -5px);
  }
  to {
    transform: translate(0);
  }
}

@keyframes move-vertical-reversed {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(0, -10px);
  }
  50% {
    transform: translate(0, -5px);
  }
  75% {
    transform: translate(0, 5px);
  }
  to {
    transform: translate(0);
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotating 10s linear infinite;
}

.move-vertical {
  animation: move-vertical 5s linear infinite;
}

.move-vertical-reversed {
  animation: move-vertical-reversed 5s linear infinite;
}

.section-effect-snow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 2; // Over section background
}

.site-effect-snow {
  position: fixed;
}

.section-content,
.section-footer {
  position: relative;
  z-index: 1;
}
.section-footer {
  background-image: url(../../assets/images/footer-bg-1.png);
  background-repeat: no-repeat;
  background-size: auto;
  // background-position: bottom;
}

@keyframes fall {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateY(35px);
    opacity: 0;
  }
}

.meteor-shower {
  display: inline-flex;
  justify-content: space-between;
  width: 30px;
  height: 135px;

  .meteor {
    &:nth-of-type(1) {
      --width: 2px;
      --height: 79px;
      --duration: 1.1s;
      --delay: 1s;
      transform: translate(0, 0) scale(1);
    }

    &:nth-of-type(2) {
      --width: 4px;
      --height: 135px;
      --duration: 1.4s;
      --delay: 1s;
      transform: translate(0, 0) scale(1);
    }

    &:nth-of-type(3) {
      --width: 2px;
      --height: 79px;
      --duration: 1.2s;
      --delay: 1.2s;
      transform: translate(0, 24px) scale(1);
    }
  }

  .drop {
    border-radius: 5px;
    background: linear-gradient(180deg, rgba(2, 7, 34, 0) 0%, rgba(2, 7, 34, .5) 40%, #4ccbc9 100%);
    width: var(--width);
    height: var(--height);
    animation-name: fall;
    animation-duration: var(--duration);
    animation-delay: var(--delay);
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    animation-fill-mode: backwards;
  }
}

@media screen and (max-width: 1440px) {
  .section-header-big {
    .section-sub-title {
      font-size: 36px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .main-content {
    padding: 160px 0 0;
  }

  .section-title {
    font-size: 32px;
  }

  .section-header-small .section-title {
    font-size: 26px;
  }

  .section-header-big {
    .section-sub-title {
      font-size: 32px;
    }
  }
}

@include media-breakpoint-down(md) {
  .main-content {
    padding: 140px 0 0;
  }

  .section-title {
    font-size: 28px;
  }

  .section-description {
    font-size: 16px;
    line-height: 26px;
  }

  .section-header-beside {
    .section-description {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .section-header-small .section-title {
    font-size: 22px;
  }

  .section-btn-callout {
    margin-top: 30px;
  }

  .section-header-big {
    .section-header-inner {
      max-width: 770px;
    }

    .section-sub-title {
      font-size: 28px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .main-content {
    padding: 120px 0 0;
  }

  .section-title {
    font-size: 24px;
  }

  .section-description {
    font-size: 16px;
    line-height: 26px;
  }

  .section-header {
    &:not(.section-header-beside ) {
      margin: 0 0 35px;
    }
  }

  .section-header-inner {
    max-width: 540px;
  }

  .section-header-small .section-title {
    font-size: 20px;
  }

  .section-btn-callout {
    margin-top: 30px;
  }

  .section-header-big {
    .section-title {
      font-size: 18px;
    }

    .section-description {
      font-size: 16px;
      line-height: 26px;
    }

    .section-sub-title {
      font-size: 24px;
    }

    .section-description-wrap {
      margin: 10px 0 0;
    }

    .section-header-inner {
      max-width: 570px;
    }
  }
}

@include media-breakpoint-down(xs) {
  .section-description-wrap {
    margin: 10px 0 0;
  }

  .section-description {
    font-size: 14px;
    line-height: 24px;
  }

  .section-header-beside {
    .section-description-wrap {
      margin: 10px 0 0;
    }
  }

  .section-header-big {
    .section-header-inner {
      max-width: 470px;
    }

    .section-description {
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.anticon {
  vertical-align: 0.125em !important
}

.ant-drawer {
  .ant-drawer-mask {
    background-color: #262c4a96;
  }

  .primary-menu {
    display: block;
  }
}

.ant-typography {
  margin-bottom: 0 !important;
  justify-content: space-between;
}

.ant-image-mask {
  border-radius: 8px;
}

.ant-image-preview-content {
  img {
    display: inline-block;
  }
}

.ant-drawer-content-wrapper {
  .ant-drawer-content {
    .ant-drawer-wrapper-body {
      .ant-drawer-header {
        background-color: #080e26;
        border-bottom: 1px solid #151A30;

        .ant-drawer-header-title {
          .ant-drawer-title {
            //text-transform: none;
            font-weight: bolder;
            font-size: 20px;
            color: #ffffff;
          }
        }
      }

      .ant-drawer-body {
        background-color: #080e26;

        nav.primary-menu {
          .nav {
            justify-content: start;
            flex-direction: column;

            .nav-item {
              .nav-link {
                width: fit-content;
                padding-left: 0;
              }
            }
          }
        }
      }
    }

  }
}
