.astar-page-container-wrapper {
  // position: relative;
  span {
    &.change-color {
      color: #4dfff6;
    }
    &.change-color-1 {
      color: #4ccbc9;
    }
    &.change-color-2 {
      color: #48c8f0;
    }
    &.change-color-3 {
      color: #e4007b;
    }
    &.change-underline {
      text-decoration: underline;
      cursor: pointer;
    }
    &.change-small-text {
      margin-top: 16px;
      display: block;
      font-family: Poppins;
      font-size: 13px;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
    }
    &.change-color-white {
      color: #fff;
    }
    &.change-font-weight-600 {
      font-weight: 600;
    }
  }
  .astar-page-bg-1 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    img {
      object-fit: cover;
      &:nth-child(1) {
        display: block;
      }
      &:nth-child(2) {
        display: none;
      }
    }
  }
}

.share-rewards-modal {
  width: 771px !important;
  z-index: 99999;
  .ant-modal-content {
    border-radius: 17px;
    background-color: #21063c;

    &::before {
      position: absolute;
      z-index: -1;
      content: "";
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
      border-radius: 20px;
      background: linear-gradient(90deg, #e4007b 0%, #4ccbc9 100%);
      box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
    }
    .ant-modal-close {
      display: none;
    }
    .ant-modal-body {
      padding: 30px;
      .close-btn {
        position: absolute;
        bottom: calc(100% + 24px);
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        transition: all 0.3s ease;
        &:active {
          transform: translateX(-50%) translateY(5px);
        }
        img {
          width: 40px;
          height: 40px;
          &:nth-child(1) {
            display: block;
          }
          &:nth-child(2) {
            width: 20px;
            height: 20px;
            display: none;
          }
        }
      }
      .share-rewards-modal-header {
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        text-transform: none;
        margin-bottom: 6px;
      }
      p {
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        text-transform: none;
        margin: 0;
      }
      .share-rewards-img {
        position: relative;
        img {
          &:nth-child(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 338px;
          }
        }
      }
      .benefit-list {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        padding: 0;
        margin: 16px 0 40px 0;
        .benefit-item {
          padding: 10px 16px;
          background-color: #351c4f;
          border-radius: 80px;
          display: flex;
          gap: 16px;
          align-items: center;
          width: 231px;
          .index {
            position: relative;
            span {
              width: 28px;
              height: 28px;
              color: #fff;
              background-color: #e4007b;
              border-radius: 50%;
              display: inline-block;
              text-align: center;
              line-height: 28px;
              position: relative;
              z-index: 1;
            }
            .border-index {
              position: absolute;
              z-index: 0;
              content: "";
              background-color: #502f70;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border-radius: 50%;
              animation: scaleCircle infinite 3s alternate;
            }
          }
          .text {
            color: #90829d;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
            text-transform: none;
          }
        }
      }
      .button-wrapper {
        display: flex;
        justify-content: center;
        gap: 16px;
        button {
          &:active {
            transform: translateY(5px) !important;
          }
          &:hover {
            transform: translateY(0);
          }
          &:focus {
            transform: translateY(0);
          }
        }
        .share {
          padding: 9px 24px;
          border-radius: 8px;
          background: linear-gradient(85deg, #e4007b 2.68%, #4ccbc9 100%);
          color: #fff;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
        }
        .stake {
          padding: 9px 24px;
          border-radius: 8px;
          background: linear-gradient(
            111deg,
            #95008e 6.93%,
            #3d94fa 51.18%,
            #04d8ff 115.93%
          );
          color: #fff;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
        }
      }
    }
  }
}

.banner-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      width: 323px;
      padding: 8px;
      border-radius: 7px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }
  }
}

.astar-page-container {
  font-family: Poppins;
  max-width: 1170px;
  margin: 0 auto;
  // padding-bottom: 121px;

  button {
    height: fit-content;
    width: unset;
    max-height: unset;
    max-width: unset;
    min-height: unset;
    min-width: unset;
  }
  .astar-page-header {
    margin-top: 149px;
    margin-bottom: 80px;
    p {
      margin: 0;
      color: #fff;
      text-align: center;
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: 88px;
      text-transform: none;
    }
  }
  .astar-page-section-1 {
    display: flex;
    gap: 30px;
    align-items: flex-end;
    justify-content: center;
    margin: 0 0 100px 0;
    .astar-page-section-1-left {
      height: 100%;
      margin-top: -3px;
      &.not-connect {
        display: flex;
        flex-direction: column;
        gap: 28px;
      }
      .wallet-info {
        color: #fff;
        .wallet-info-header {
          font-family: Poppins;
          margin: 0;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 44px;
          text-transform: capitalize;
        }
        .wallet-list {
          margin-bottom: 40px;
          margin-top: 28px;
          .wallet-item {
            height: fit-content;
            display: flex;
            align-items: flex-start;
            margin-bottom: 16px;
            .wallet-item-text {
              display: flex;
              flex-direction: column;
              gap: 25px;
              justify-content: space-between;
            }
            .sub-wallet-list {
              display: flex;
              flex-direction: column;
              gap: 8px;
            }
            span {
              display: block;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 28px;
              text-transform: capitalize;
              &:nth-child(1) {
                padding-top: 8px;
              }
              &.wallet-address {
                margin-left: 8px;
                font-size: 18px;
                line-height: 30px;
                font-weight: 500;
                padding: 7px 24px 7px 24px;
                background-color: rgba(161, 107, 216, 0.3);
                border-radius: 75px;
                cursor: pointer;
                transition: all 0.2s ease;
                &:hover {
                  text-decoration: underline;
                  color: #4ccbc9;
                }
              }
            }
          }
        }
      }
      .stake-info-wrapper {
        position: relative;
        border-radius: 20px;
        &::before {
          position: absolute;
          z-index: -1;
          content: "";
          top: -3px;
          right: -3px;
          bottom: -3px;
          left: -3px;
          border-radius: 20px;
          background: linear-gradient(90deg, #e4007b 0%, #4ccbc9 100%);
          box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
        }
        .stake-info {
          background-color: #21063c;
          padding: 16px;
          border-radius: 17px;
          position: relative;
          overflow: hidden;
          &.not-connect {
            padding-bottom: 160px;
            .not-connect-bg {
              display: block;
            }
          }
          .total-stake {
            background-color: #351c4f;
            border-radius: 10px;
            padding: 24px 20px;
            margin-bottom: 16px;
            position: relative;
            z-index: 1;
            .total-stake-header {
              color: #fff;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 28px;
              margin: 0;
              margin-bottom: 16px;
              text-transform: capitalize;
            }
            .total-stake-number {
              background: linear-gradient(
                110deg,
                #95008e 0%,
                #3d94fa 10%,
                #04d8ff 80.31%
              );
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-family: Poppins;
              font-size: 40px;
              font-style: normal;
              font-weight: 700;
              line-height: 52px;
              .total-stake-unit {
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 36px;
                margin-left: 8px;
                background: linear-gradient(
                  139deg,
                  #95008e -49.14%,
                  #3d94fa 48.38%,
                  #04d8ff 116.75%
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }
        }
        .claimable-rewards {
          padding: 24px 16px 24px 20px;
          background-color: #351c4f;
          border-radius: 10px;
          margin-bottom: 16px;
          position: relative;
          z-index: 1;
          min-width: 338px;
          .claimable-rewards-header {
            color: #fff;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            margin: 0 0 20px 0;
            text-transform: capitalize;
          }
          .claimable-rewards-content {
            display: flex;
            gap: 44px;
            align-items: flex-end;
            justify-content: space-between;
            .claimable-rewards-number {
              font-family: Poppins;
              font-size: 40px;
              font-style: normal;
              font-weight: 700;
              line-height: 52px;
              margin: 0;
              width: 170px;
              background: linear-gradient(
                110deg,
                #95008e 0%,
                #3d94fa 16%,
                #04d8ff 80.31%
              );
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              .claimable-rewards-unit {
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 36px;
                margin-left: 8px;
                background: linear-gradient(
                  139deg,
                  #95008e -49.14%,
                  #3d94fa 48.38%,
                  #04d8ff 116.75%
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
            .claimable-rewards-button {
              background-color: #4ccbc9;
              padding: 10px 16px;
              font-family: Poppins;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 30px;
              color: #280748;
              text-transform: capitalize;
              border-radius: 8px;
              &:active {
                transform: translateY(5px) !important;
              }
              &:hover {
                transform: translateY(0);
              }
              &:focus {
                transform: translateY(0);
              }
              &.disabled {
                background-color: #7fa1a1;
                color: #3d3544;
                cursor: not-allowed;
                &:active {
                  transform: translateY(0) !important;
                }
              }
            }
          }
        }
        .go-to-stake {
          width: 100%;
          padding: 10px;
          border-radius: 8px;
          background: linear-gradient(85deg, #e4007b 2.68%, #4ccbc9 100%);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
          text-transform: capitalize;
          color: #fff;
          font-family: Poppins;
          position: relative;
          z-index: 1;
          &:active {
            transform: translateY(5px) !important;
          }
          &:hover {
            transform: translateY(0);
          }
          &:focus {
            transform: translateY(0);
          }
        }
        .not-connect-bg {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 0;
          display: none;
        }
      }
    }
    .astar-page-section-1-right {
      height: 100%;
      background-color: #21063c;
      position: relative;
      padding: 16px 12px;
      border-radius: 17px;
      &::before {
        content: "";
        position: absolute;
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;
        background: linear-gradient(85deg, #e4007b 2.68%, #4ccbc9 100%);
        z-index: -1;
        border-radius: 20px;
        box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
      }
      .stake-benefit-img {
        display: none;
      }
      .stake-info {
        .stake-info-banner {
          display: flex;
          gap: 18px;
          margin-bottom: 16px;
          .stake-banner-item {
            width: calc((100% - 16px) / 2);
            &.first {
              padding: 24px 16px;
              background-color: #351c4f;
              border-radius: 10px;
              .stake-banner-item-header {
                color: #fff;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                text-transform: capitalize;
                margin: 0 0 16px 0;
              }
              .stake-banner-item-number {
                font-family: Poppins;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 52px;
                margin: 0 0 24px 0;
                background: linear-gradient(
                  110deg,
                  #95008e 0%,
                  #3d94fa 22%,
                  #04d8ff 80.31%
                );
                background-clip: text;
                color: transparent;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                display: flex;
                align-items: flex-end;
                gap: 8px;
                .stake-banner-item-unit {
                  font-family: Poppins;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 36px;
                  background: linear-gradient(
                    139deg,
                    #95008e -49.14%,
                    #3d94fa 48.38%,
                    #04d8ff 116.75%
                  );
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }
              .stake-banner-item-next {
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                color: #90829d;
                text-transform: capitalize;
                .stake-banner-item-next-time {
                  font-weight: 600;
                  color: #fff;
                }
              }
            }
            &.second {
              background-color: #351c4f;
              border-radius: 10px;
              padding: 24px 14px 20px;
              position: relative;
              .stake-banner-item-bg {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 10px;
                z-index: 0;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .stake-banner-item-content {
                position: relative;
                z-index: 1;
                .stake-banner-item-header {
                  font-family: Poppins;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 28px;
                  color: #fff;
                  text-transform: capitalize;
                  margin: 0 0 16px 0;
                }
                .stake-banner-item-number {
                  color: #fff;
                  font-family: Poppins;
                  font-size: 32px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 52px;
                  margin: 0 0 16px;
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  img {
                    width: 24px;
                    height: 24px;
                  }
                }
                .stake-banner-item-next {
                  color: #fff;
                  font-family: Poppins;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 36px;
                  margin: 0;
                  text-transform: none;
                }
              }
            }
          }
        }
        .stake-benefit {
          position: relative;
          padding: 24px 16px 0;
          border-radius: 10px;
          overflow: hidden;
          .stake-benefit-bg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .stake-benefit-content {
            position: relative;
            z-index: 1;
            margin-bottom: 135px;
            .stake-benefit-header {
              color: #fff;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 28px;
              text-transform: none;
              margin: 0 0 16px;
            }
            .stake-benefit-list {
              display: flex;
              gap: 16px;
              padding: 0;
              margin: 0;
              .stake-benefit-item {
                width: calc((100% - 32px) / 3);
                padding: 16px;
                background-color: rgba(161, 107, 216, 0.3);
                border-radius: 10px;
                margin: 0;
                .stake-benefit-item-index-wrapper {
                  position: relative;
                  .stake-benefit-item-index {
                    display: block;
                    width: 32px;
                    height: 32px;
                    border-radius: 36px;
                    background-color: #e4007b;
                    color: #fff;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px;
                    text-align: center;
                    line-height: 32px;
                    margin: 0 0 16px 0;
                    position: relative;
                    z-index: 10;
                  }
                  .border-index {
                    position: absolute;
                    content: "";
                    background-color: #502f70;
                    width: 32px;
                    height: 32px;
                    top: 0;
                    left: 0;
                    border-radius: 50%;

                    animation: scaleCircle infinite 1s alternate;
                  }
                }

                .stake-benefit-item-content {
                  color: #fff;
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;
                  text-transform: none;
                  .change-color-1 {
                    font-weight: 600;
                    color: #4dfff6;
                  }
                  .change-color-2 {
                    font-weight: 600;
                    color: #48c8f0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .astar-page-section-2 {
    margin-bottom: 121px;
    .astar-page-section-2-header {
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px;
      text-transform: none;
      margin: 0 0 68px;
    }
    .astar-guide-list {
      display: flex;
      gap: 30px;
      .astar-guide-item {
        width: calc(100% - 60px);
        height: fit-content;
        position: relative;

        .astar-guide-item-header {
          color: #fff;
          padding: 13px 30.5px;
          text-align: center;
          leading-trim: both;
          text-edge: cap;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 100% */
          text-transform: uppercase;
          border-radius: 8px;
          background: #a16bd8;
          box-shadow: 0px 4px 0px 0px #7950a2;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          z-index: 2;
        }

        &.step-1 {
          &::before {
            content: "";
            position: absolute;
            top: -2px;
            bottom: -2px;
            left: -2px;
            right: -2px;
            border-radius: 12px;
            background: linear-gradient(90deg, #e4007b 0%, #4ccbc9 100%);
            box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
            z-index: -1;
          }
          .astar-guide-item-content {
            background-color: #21063c;
            position: relative;
            z-index: 1;
            padding: 48px 16px 24px;
            color: #90829d;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            border-radius: 10px;
            text-transform: none;
          }
        }

        &.step-2 {
          &::before {
            content: "";
            position: absolute;
            top: 58px;
            bottom: -62px;
            left: -2px;
            right: -2px;
            border-radius: 12px;
            background: linear-gradient(90deg, #e4007b 0%, #4ccbc9 100%);
            box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
            z-index: -1;
          }
          .astar-guide-item-header {
            top: 58px;
          }
          .astar-guide-item-content {
            background-color: #21063c;
            position: relative;
            z-index: 1;
            top: 60px;
            padding: 28px 16px 24px;
            color: #90829d;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            border-radius: 10px;
            text-transform: none;
          }
        }

        &.step-3 {
          &::before {
            content: "";
            position: absolute;
            top: 118px;
            bottom: -122px;
            left: -2px;
            right: -2px;
            border-radius: 12px;
            background: linear-gradient(90deg, #e4007b 0%, #4ccbc9 100%);
            box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
            z-index: -1;
          }
          .astar-guide-item-header {
            top: 118px;
          }
          .astar-guide-item-content {
            background-color: #21063c;
            position: relative;
            z-index: 1;
            top: 120px;
            padding: 40px 16px 24px;
            color: #90829d;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            border-radius: 10px;
            text-transform: none;
            .change-color {
              color: #4dfff6;
            }
          }
        }
      }
    }
    .astar-guide-note-wrapper {
      display: flex;
      gap: 60px;
      margin-top: 188px;
      align-items: center;
      .astar-guide-note {
        max-width: 740px;
        padding: 24px;
        background-color: rgba(53, 28, 79, 0.7);
        border-radius: 10px;
        .astar-guide-note-header {
          color: #fff;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
          text-transform: none;
        }
        .astar-guide-note-list {
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          .astar-guide-note-item {
            display: flex;
            gap: 16px;
            align-items: flex-start;
            .astar-guide-note-item-icon {
              margin-top: 5px;
              width: fit-content;
              height: fit-content;
              img {
                width: 24px;
                height: 24px;
                max-width: none;
                // object-fit: contain;
              }
            }
            .astar-guide-note-item-content {
              display: block;
              color: #90829d;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px;
              text-transform: none;
            }
          }
        }
      }
      .tutorial {
        color: #fff;
        text-transform: none;
        .tutorial-header {
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          margin-bottom: 8px;
        }
        .tutorial-content {
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 56px;
        }
      }
    }
  }
}

.astar-page-section-2-wrapper {
  position: relative;
  .astar-page-section-2-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.astar-page-footer-wrapper {
  padding-top: 120px;
  padding-bottom: 292px;
  position: relative;
  .astar-page-footer-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      &:nth-child(1) {
        display: block;
      }
      &:nth-child(2) {
        display: none;
      }
    }
  }
  .astar-page-footer-img {
    position: absolute;
    top: 250px;
    left: 0;
    z-index: -1;
    img {
      width: 666px;
      height: 496px;
      &:nth-child(1) {
        display: block;
      }
      &:nth-child(2) {
        display: none;
      }
    }
  }
  .astar-page-footer {
    display: flex;
    color: #fff;
    max-width: 1170px;
    margin: 0 auto;
    .astar-page-footer-left {
      width: 31%;
      h4 {
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 56px;
        text-transform: none;
      }
      img {
        display: none;
      }
    }
    .astar-page-footer-right {
      width: 69%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .info-list {
        display: flex;
        flex-direction: column;
        .info-item {
          display: flex;
          gap: 8px;
          .icon {
            // margin-top: 4px;
            img {
              width: 24px;
              max-width: none;
              object-fit: contain;
            }
          }
        }
      }
      .divide-y {
        // padding: 32px;
        background-color: #351c4f;
        border: none;
        .px-5 {
          border-color: rgba(237, 237, 237, 0.1);
          text-transform: none;
          margin: 0;
          padding: 17px 32px 32px 32px;
          height: fit-content;
          min-height: unset;
          .qa-content {
            color: #90829d;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            &:not(:last-child) {
              margin-bottom: 16px;
            }
          }
        }
        button {
          height: fit-content;
          background-color: #351c4f;
          color: #90829d;
          padding: 32px !important;
          &.text-gray-900 {
            padding: 32px 32px 16px 32px !important;
            color: #fff;
            h2 {
              color: inherit;
            }
          }

          &:focus {
            --tw-ring-opacity: 0;
          }
          &:hover {
            background-color: unset;
          }
          h2 {
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            text-transform: none;
            color: inherit;
          }
        }
      }
    }
  }
}

.claim-rewards-modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  display: none;
  &.active {
    display: flex;
  }
  .claim-rewards-modal-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #21063c;
    opacity: 0.9;
    z-index: -1;
  }
  .claim-rewards-modal {
    position: relative;
    color: #fff;
    display: block;
    .claim-rewards-modal-header {
      font-family: Poppins;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 56px;
      margin-bottom: 24px;
    }
    .claim-rewards-modal-close {
      position: absolute;
      right: 0;
      top: 12px;
      cursor: pointer;
      transition: all 0.2s ease;
      img {
        width: 32px;
        height: 32px;
      }
      &:active {
        transform: translateY(5px);
      }
    }
    .claim-rewards-modal-content {
      position: relative;
      width: 1169px;
      max-height: 478px;
      height: fit-content;
      // overflow:auto;
      background-color: #21063c;
      border-radius: 17px;
      &::before {
        content: "";
        position: absolute;
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
        background: linear-gradient(85deg, #e4007b 2.68%, #4ccbc9 100%);
        z-index: -1;
        border-radius: 20px;
        box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
      }
      .claim-rewards-table {
        .claim-rewards-table-header {
          background-color: #90829d28;
          display: flex;
          padding: 30px 30px 14px 30px;
          gap: 30px;
          .claim-rewards-table-header-item {
            position: relative;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
            text-transform: none;
            display: flex;
            align-items: center;
            gap: 8px;
            .amount-tooltip {
              padding: 8px;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
              text-align: center;
              border-radius: 7px;
              width: 369px;
              background-color: #a16bd8;
              left: 0 !important;
              top: 0 !important;
              transform: translateX(-22%) translateY(-100%);
              .absolute {
                background-color: #a16bd8;
              }
            }
            img {
              width: 24px;
              height: 24px;
            }
            p {
              margin: 0;
            }
            &:nth-child(1) {
              width: 241px;
            }
            &:nth-child(2) {
              width: 270px;
            }
            &:nth-child(3) {
              width: 270px;
            }
            &:nth-child(4) {
              width: 240px;
            }
          }
        }
        .claim-rewards-table-list {
          display: flex;
          flex-direction: column;
          padding: 0 30px 16px 29px;
          .claim-rewards-table-item {
            display: flex;
            gap: 30px;
            height: 64px;
            align-items: center;
            .claim-rewards-item-index {
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 36px;
              width: 241px;
            }
            .claim-rewards-item-amount {
              display: flex;
              width: 270px;
              .reward-icon {
                margin-right: 7px;
                img {
                  width: 24px;
                  height: 24px;
                }
              }
              .reward-number {
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                color: #4ccbc9;
              }
            }
            .claim-rewards-item-time {
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 36px;
              width: 270px;
            }
            .claim-rewards-round {
              width: 240px;
              .pending {
                cursor: not-allowed;
                padding: 3px 86px;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
                color: #efaa5d;
                border-radius: 40px;
                background: rgba(239, 170, 93, 0.2);
              }
              .claimed {
                cursor: not-allowed;
                padding: 3px 84.5px;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
                color: #90829d;
                border-radius: 40px;
                background: var(--Background-04, #452863);
              }
              input {
                width: 20px;
                height: 20px;
                border-radius: 3px;
                &::before {
                  background-color: transparent;
                  border: 1.5px solid #90829d;
                  width: 20px;
                  height: 20px;
                }
                &:focus {
                  --tw-ring-inset: none;
                }
              }
              label {
                cursor: pointer;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 36px;
                user-select: none;
              }
            }
          }
        }
      }
    }
    .claim-rewards-button-wrapper {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      .claim-rewards-button {
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        padding: 10px 32px;
        background: linear-gradient(85deg, #e4007b 2.68%, #4ccbc9 100%);
        border-radius: 8px;
        color: #fff;
        text-transform: none;
        &.disabled {
          cursor: not-allowed;
          background: linear-gradient(85deg, #af6d90 2.68%, #76b9b8 100%);
          color: #ffffff59;
          &:active {
            transform: translateY(0);
          }
        }
        &:hover,
        &:focus {
          transform: translateY(0);
        }
        &:active {
          transform: translateY(5px);
        }
      }
    }
  }
  .claim-rewards-modal-mobile {
    display: none;
  }
}

@keyframes scaleCircle {
  from {
    scale: 1;
  }
  to {
    scale: 1.4;
  }
}

@media (max-width: 1300px) {
  .astar-page-container-wrapper {
    .astar-page-container {
      scale: 0.9;
      .astar-page-header {
        margin-top: 80px;
      }
      .astar-page-section-1 {
        margin-bottom: 20px;
      }
      .astar-page-section-2 {
        margin-bottom: 80px;
      }
    }
    .astar-page-footer-wrapper {
      padding-top: 80px;
      padding-bottom: 200px;
      .astar-page-footer {
        scale: 0.9;
      }
    }
  }
  .claim-rewards-modal-wrapper {
    .claim-rewards-modal {
      scale: 0.9;
    }
  }
}

@media (max-width: 1100px) {
  .astar-page-container-wrapper {
    .astar-page-container {
      scale: 0.8;
      .astar-page-header {
        margin-top: 60px;
      }
      .astar-page-section-1 {
        margin-bottom: -50px;
        .astar-page-section-1-left {
          &.not-connect {
            gap: 37px;
          }
          .stake-info-wrapper {
            .stake-info {
              .total-stake {
                padding: 17px 15px;
                .total-stake-number {
                  font-size: 30px;
                  .total-stake-unit {
                    font-size: 16px;
                  }
                }
              }
            }
            .claimable-rewards {
              padding: 17px 15px;
              .claimable-rewards-content {
                justify-content: space-between;
                gap: 10px;
                .claimable-rewards-number {
                  font-size: 30px;
                  .claimable-rewards-unit {
                    font-size: 16px;
                  }
                }
                .claimable-rewards-button {
                  font-size: 16px;
                }
              }
            }
          }
        }
        .astar-page-section-1-right {
          .stake-info {
            .stake-info-banner {
              .stake-banner-item {
                &.first {
                  .stake-banner-item-number {
                    font-size: 30px;
                    margin-bottom: 16px;
                    .stake-banner-item-unit {
                      font-size: 16px;
                    }
                  }
                }
                &.second {
                  .stake-banner-item-number {
                    font-size: 30px;
                    margin-bottom: 16px;
                    .stake-banner-item-unit {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
            .stake-benefit {
              padding: 22px 14px 0;
              .stake-benefit-content {
                .stake-benefit-list {
                  .stake-benefit-item {
                    .stake-benefit-item-index-wrapper {
                      .stake-benefit-item-index {
                        margin: 0 0 10px 0;
                        width: 24px;
                        height: 24px;
                        font-size: 16px;
                        line-height: 24px;
                      }
                      .border-index {
                        width: 24px;
                        height: 24px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .astar-page-section-2 {
        margin-bottom: 10px;
        .astar-guide-list {
          .astar-guide-item {
            .astar-guide-item-header {
              padding: 10px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .astar-page-footer-wrapper {
      padding-top: 10px;
      padding-bottom: 100px;
      .astar-page-footer {
        scale: 0.8;
      }
    }
  }
  .claim-rewards-modal-wrapper {
    .claim-rewards-modal {
      scale: 0.8;
    }
  }
}

@media (max-width: 1000px) {
  .astar-page-container-wrapper {
    .astar-page-container {
      scale: 0.8;
      width: 100%;
      max-width: 1000px;
      .astar-page-header {
        margin-top: 60px;
      }
      .astar-page-section-1 {
        margin-bottom: -50px;
        width: 100%;
        .astar-page-section-1-left {
          .stake-info-wrapper {
            .stake-info {
              .total-stake {
                padding: 17px 15px;
                .total-stake-number {
                  font-size: 30px;
                  .total-stake-unit {
                    font-size: 16px;
                  }
                }
              }
            }
            .claimable-rewards {
              padding: 17px 15px;
              .claimable-rewards-content {
                justify-content: space-between;
                gap: 10px;
                .claimable-rewards-number {
                  font-size: 30px;
                  .claimable-rewards-unit {
                    font-size: 16px;
                  }
                }
                .claimable-rewards-button {
                  font-size: 16px;
                  padding: 10px 14px;
                }
              }
            }
          }
        }
        .astar-page-section-1-right {
          .stake-info {
            .stake-info-banner {
              .stake-banner-item {
                &.first {
                  .stake-banner-item-number {
                    font-size: 30px;
                    margin-bottom: 16px;
                    flex-wrap: wrap;
                    .stake-banner-item-unit {
                      font-size: 16px;
                    }
                  }
                }
                &.second {
                  .stake-banner-item-number {
                    font-size: 30px;
                    margin-bottom: 16px;
                    .stake-banner-item-unit {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
            .stake-benefit {
              padding: 22px 14px 0;
              .stake-benefit-content {
                margin-bottom: 115px;
                .stake-benefit-list {
                  .stake-benefit-item {
                    .stake-benefit-item-index-wrapper {
                      .stake-benefit-item-index {
                        margin: 0 0 10px 0;
                        width: 24px;
                        height: 24px;
                        font-size: 16px;
                        line-height: 24px;
                      }
                      .border-index {
                        width: 24px;
                        height: 24px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .astar-page-section-2 {
        margin-bottom: 10px;
      }
    }
    .astar-page-footer-wrapper {
      padding-top: 10px;
      padding-bottom: 100px;
      .astar-page-footer {
        scale: 0.8;
      }
    }
  }
  .claim-rewards-modal-wrapper {
    .claim-rewards-modal {
      scale: 0.75;
    }
  }
}

@media (max-width: 900px) {
  .astar-page-container-wrapper {
    .astar-page-container {
      .astar-page-section-1 {
        .astar-page-section-1-left {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .astar-page-section-1-right {
          .stake-info {
            .stake-info-banner {
              .stake-banner-item {
                &.first {
                  .stake-banner-item-number {
                    font-size: 24px;
                    line-height: 30px;
                    // gap:0;
                    // margin: 0;
                    .stake-banner-item-unit {
                      font-size: 14px;
                      line-height: 20px;
                    }
                  }
                }
                &.second {
                  .stake-banner-item-number {
                    font-size: 24px;
                    line-height: 30px;
                    .stake-banner-item-unit {
                      font-size: 14px;
                      line-height: 20px;
                    }
                  }
                }
              }
            }
            .stake-benefit {
              .stake-benefit-content {
                margin-bottom: 20px;
                .stake-benefit-list {
                  flex-direction: row;
                  flex-wrap: wrap;
                  .stake-benefit-item {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .claim-rewards-modal-wrapper {
    .claim-rewards-modal {
      scale: 0.65;
    }
  }
}

@media (max-width: 879px) {
  .astar-page-container {
    .astar-page-section-1 {
      .astar-page-section-1-left {
        display: flex;
        flex-direction: column;
        gap: 45px;
      }
    }
  }
}

@media (max-width: 815px) {
  .astar-page-container-wrapper {
    .astar-page-container {
      .astar-page-section-1 {
        .astar-page-section-1-right {
          .stake-info {
            .stake-benefit {
              .stake-benefit-content {
                margin-bottom: 92px;
              }
            }
          }
        }
      }
      .astar-page-section-2 {
        .astar-guide-list {
          .astar-guide-item {
            .astar-guide-item-header {
              padding: 10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .claim-rewards-modal-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: none;
    display: none;
    padding: 0 16px;
    &.active {
      display: flex;
    }
    .claim-rewards-modal-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #21063c;
      opacity: 0.9;
      z-index: -1;
    }
    .claim-rewards-modal {
      position: relative;
      color: #fff;
      display: none;
      .claim-rewards-modal-header {
        font-family: Poppins;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 56px;
        margin-bottom: 24px;
      }
      .claim-rewards-modal-close {
        position: absolute;
        right: 0;
        top: 12px;
        cursor: pointer;
        transition: all 0.2s ease;
        img {
          width: 32px;
          height: 32px;
        }
      }
      .claim-rewards-modal-content {
        position: relative;
        width: 1169px;
        max-height: 478px;
        height: fit-content;
        // overflow:auto;
        background-color: #21063c;
        border-radius: 20px;
        &::before {
          content: "";
          position: absolute;
          top: -3px;
          bottom: -3px;
          left: -3px;
          right: -3px;
          background: linear-gradient(85deg, #e4007b 2.68%, #4ccbc9 100%);
          z-index: -1;
          border-radius: 20px;
          box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
        }
        .claim-rewards-table {
          .claim-rewards-table-header {
            background-color: #90829d28;
            display: flex;
            padding: 30px 30px 14px 30px;
            gap: 30px;
            .claim-rewards-table-header-item {
              position: relative;
              font-family: Poppins;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 36px;
              text-transform: none;
              display: flex;
              align-items: center;
              gap: 8px;
              .amount-tooltip {
                padding: 8px;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                text-align: center;
                border-radius: 7px;
                width: 369px;
                background-color: #a16bd8;
                left: 0 !important;
                top: 0 !important;
                transform: translateX(-22%) translateY(-100%);
                .absolute {
                  background-color: #a16bd8;
                }
              }
              img {
                width: 24px;
                height: 24px;
              }
              p {
                margin: 0;
              }
              &:nth-child(1) {
                width: 241px;
              }
              &:nth-child(2) {
                width: 270px;
              }
              &:nth-child(3) {
                width: 270px;
              }
              &:nth-child(4) {
                width: 240px;
              }
            }
          }
          .claim-rewards-table-list {
            display: flex;
            flex-direction: column-reverse;
            padding: 0 30px 16px 29px;
            .claim-rewards-table-item {
              display: flex;
              gap: 30px;
              height: 64px;
              align-items: center;
              &:not(:first-child) {
                border-bottom: 2px solid rgba(144, 130, 157, 0.1);
              }
              .claim-rewards-item-index {
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 36px;
                width: 241px;
              }
              .claim-rewards-item-amount {
                display: flex;
                width: 270px;
                .reward-icon {
                  img {
                    width: 24px;
                    height: 24px;
                  }
                }
                .reward-number {
                  font-family: Poppins;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 26px;
                  color: #4ccbc9;
                }
              }
              .claim-rewards-item-time {
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 36px;
                width: 270px;
              }
              .claim-rewards-round {
                width: 240px;
                input {
                  width: 20px;
                  height: 20px;
                  border-radius: 3px;
                  &::before {
                    background-color: transparent;
                    border: 1.5px solid #90829d;
                    width: 20px;
                    height: 20px;
                  }
                  &:focus {
                    --tw-ring-inset: none;
                  }
                }
                label {
                  font-family: Poppins;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 36px;
                }
              }
            }
          }
        }
      }
      .claim-rewards-button-wrapper {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        .claim-rewards-button {
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px;
          padding: 10px 32px;
          background: linear-gradient(85deg, #e4007b 2.68%, #4ccbc9 100%);
          border-radius: 8px;
          color: #fff;
          text-transform: none;
        }
      }
    }
    .claim-rewards-modal-mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: fit-content;
      max-height: 80%;
      .claim-rewards-modal-close {
        width: fit-content;
        height: fit-content;
        margin: 0 auto;
        margin-bottom: 16px;
        cursor: pointer;
        transition: all 0.2s ease;
        img {
          width: 32px;
          height: 32px;
        }
        &:active {
          transform: translateY(3px);
        }
      }
      .claim-rewards-modal-header {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 16px;
        text-transform: none;
        text-align: center;
      }
      .claim-rewards-modal-content {
        position: relative;
        background-color: #21063c;
        margin-bottom: 16px;
        border-radius: 8.5px;
        max-height: 90%;
        &::before {
          content: "";
          position: absolute;
          top: -1.5px;
          bottom: -1.5px;
          left: -1.5px;
          right: -1.5px;
          background: linear-gradient(85deg, #e4007b 2.68%, #4ccbc9 100%);
          z-index: -1;
          border-radius: inherit;
          box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
        }
        .claim-rewards-modal-list {
          padding: 24px 16px 19px;
          display: flex;
          flex-direction: column;
          gap: 24px;
          max-height: 100%;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 0;
          }
          .claim-rewards-modal-item {
            padding-bottom: 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-bottom: 2px solid rgba(144, 130, 157, 0.15);
            .item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              span {
                font-family: Poppins;
                font-size: 13px;
                line-height: 20px;
                // position: relative;

                color: #fff;
                &:nth-child(1) {
                  font-weight: 600;
                }
                &:nth-child(2) {
                  font-weight: 500;
                }
              }
              &.amount {
                span {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  // position: relative;
                  .amount-tooltip {
                    padding: 8px;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    text-align: center;
                    border-radius: 3px;
                    width: 150px;
                    background-color: #a16bd8;
                    transform: translateY(10px);
                    // left: 2.5% !important;
                    // top: 50% !important;
                    // transform: translateX(-5%) translateY(-100%);
                    .absolute {
                      background-color: #a16bd8;
                    }
                  }
                  &:nth-child(1) {
                    img {
                      width: 20px;
                      height: 20px;
                    }
                  }
                  &:nth-child(2) {
                    img {
                      display: inline-block;
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
              }
              &.claim {
                label {
                  color: #fff;
                  font-family: Poppins;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  user-select: none;
                  cursor: pointer;
                  input {
                    width: 20px;
                    height: 20px;
                    border-radius: 3px;
                    &::before {
                      background-color: transparent;
                      border: 1.5px solid #90829d;
                      width: 20px;
                      height: 20px;
                    }
                    &:focus {
                      --tw-ring-inset: none;
                    }
                  }
                }
              }
              .pending {
                cursor: not-allowed;
                padding: 3px 16px;
                font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                color: #efaa5d;
                border-radius: 40px;
                background: rgba(239, 170, 93, 0.2);
              }
              .claimed {
                cursor: not-allowed;
                padding: 3px 16px;
                font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                color: #90829d;
                border-radius: 40px;
                background: #452863;
              }
            }
          }
        }
      }
      .claim-rewards-button {
        margin: 0 auto;
        padding: 6px 74px;
        border-radius: 8px;
        background: linear-gradient(85deg, #e4007b 2.68%, #4ccbc9 100%);
        color: #fff;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        text-transform: none;
        &:hover,
        &:focus {
          transform: translateY(0);
        }
        &:active {
          transform: translateY(5px);
        }
        &.disabled {
          cursor: not-allowed;
          background: linear-gradient(85deg, #af6d90 2.68%, #76b9b8 100%);
          color: #ffffff59;
          &:active {
            transform: translateY(0);
          }
        }
      }
    }
  }

  .share-rewards-modal {
    width: 771px !important;
    .ant-modal-content {
      border-radius: 8.5px;
      background-color: #21063c;

      &::before {
        position: absolute;
        z-index: -1;
        content: "";
        top: -1.5px;
        right: -1.5px;
        bottom: -1.5px;
        left: -1.5px;
        border-radius: 10px;
        background: linear-gradient(90deg, #e4007b 0%, #4ccbc9 100%);
        box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
      }
      .ant-modal-close {
        display: none;
      }
      .ant-modal-body {
        padding: 24px 8px;
        .close-btn {
          position: absolute;
          top: 8px;
          right: 8px;
          width: 20px;
          height: 20px;
          bottom: unset;
          left: unset;
          transform: translateX(0);
          &:active {
            transform: translateX(0) translateY(5px);
          }
          img {
            width: 40px;
            height: 40px;
            &:nth-child(1) {
              display: none;
            }
            &:nth-child(2) {
              width: 20px;
              height: 20px;
              display: block;
            }
          }
        }
        .share-rewards-modal-header {
          color: #fff;
          text-align: center;
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px;
          margin-bottom: 6px;
        }
        p {
          color: #fff;
          text-align: center;
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 21px;
          margin: 0;
        }
        .share-rewards-img {
          position: relative;
          img {
            &:nth-child(2) {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              width: 150px;
            }
          }
        }
        .benefit-list {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 0;
          margin: 16px 0 24px 0;
          .benefit-item {
            padding: 10px 16px;
            background-color: #351c4f;
            border-radius: 80px;
            display: flex;
            gap: 16px;
            align-items: center;
            width: 100%;
            .index {
              position: relative;
              span {
                width: 28px;
                height: 28px;
                color: #fff;
                background-color: #e4007b;
                border-radius: 50%;
                display: inline-block;
                text-align: center;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                position: relative;
                z-index: 1;
              }
              .border-index {
                position: absolute;
                z-index: 0;
                content: "";
                background-color: #502f70;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 50%;
                animation: scaleCircle infinite 3s alternate;
              }
            }
            .text {
              color: #90829d;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
            }
          }
        }
        .button-wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;
          button {
            &:active {
              transform: translateY(5px) !important;
            }
            &:hover {
              transform: translateY(0);
            }
            &:focus {
              transform: translateY(0);
            }
          }
          .share {
            padding: 9px 24px;
            border-radius: 8px;
            background: linear-gradient(85deg, #e4007b 2.68%, #4ccbc9 100%);
            color: #fff;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }
          .stake {
            padding: 9px 24px;
            border-radius: 8px;
            background: linear-gradient(
              111deg,
              #95008e 6.93%,
              #3d94fa 51.18%,
              #04d8ff 115.93%
            );
            color: #fff;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }
        }
      }
    }
  }

  .astar-page-container-wrapper {
    // position: relative;
    .astar-page-bg-1 {
      img {
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          display: block;
        }
      }
    }
    .astar-page-container {
      scale: 1;
      font-family: Poppins;
      max-width: 1170px;
      margin: 0 auto;
      padding: 0 16px;
      // padding-bottom: 121px;

      button {
        height: fit-content;
        width: unset;
        max-height: unset;
        max-width: unset;
        min-height: unset;
        min-width: unset;
      }
      .astar-page-header {
        margin-top: 128px;
        margin-bottom: 48px;
        p {
          color: #fff;
          text-align: center;
          font-family: Poppins;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 44px;
          text-transform: capitalize;
        }
      }
      .astar-page-section-1 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 32px;
        .astar-page-section-1-left {
          height: 100%;
          margin-top: 0;
          gap: 24px;
          width: 100%;
          .wallet-info {
            color: #fff;
            .wallet-info-header {
              font-family: Poppins;
              margin: 0;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 30px;
              text-transform: capitalize;
            }
            .wallet-list {
              margin-bottom: 0;
              margin-top: 16px;
              display: flex;
              flex-direction: column;
              gap: 24px;
              .wallet-item {
                height: fit-content;
                display: flex;
                align-items: center;
                margin-bottom: 0;
                span {
                  display: block;
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
                  text-transform: capitalize;
                  &.wallet-address {
                    margin-left: 8px;
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 500;
                    padding: 7px 16px;
                    background-color: rgba(161, 107, 216, 0.3);
                    border-radius: 75px;
                  }
                }
              }
            }
          }
          .stake-info-wrapper {
            position: relative;
            border-radius: 10px;
            &::before {
              position: absolute;
              z-index: -1;
              content: "";
              top: -1.5px;
              right: -1.5px;
              bottom: -1.5px;
              left: -1.5px;
              border-radius: 10px;
              background: linear-gradient(90deg, #e4007b 0%, #4ccbc9 100%);
              box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
            }
            .stake-info {
              background-color: #21063c;
              padding: 16px 8px;
              border-radius: 10px;

              .not-connect-bg {
                display: none;
              }
              .total-stake {
                background-color: #351c4f;
                border-radius: 8px;
                padding: 16px 16px 24px;
                margin-bottom: 16px;
                .total-stake-header {
                  color: #fff;
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
                  margin: 0;
                  margin-bottom: 14px;
                  text-transform: capitalize;
                }
                .total-stake-number {
                  background: linear-gradient(
                    110deg,
                    #95008e 0%,
                    #3d94fa 10%,
                    #04d8ff 80.31%
                  );
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  font-family: Poppins;
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 40px;
                  .total-stake-unit {
                    font-family: Poppins;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    margin-left: 8px;
                    background: linear-gradient(
                      139deg,
                      #95008e -49.14%,
                      #3d94fa 48.38%,
                      #04d8ff 116.75%
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
              }
            }
            .claimable-rewards {
              padding: 16px 16px 24px;
              background-color: #351c4f;
              border-radius: 10px;
              margin-bottom: 16px;
              min-width: unset;
              .claimable-rewards-header {
                color: #fff;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                margin: 0 0 14px 0;
                text-transform: capitalize;
              }
              .claimable-rewards-content {
                display: flex;
                gap: 41px;
                align-items: flex-end;
                .claimable-rewards-number {
                  font-family: Poppins;
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 40px;
                  margin: 0;
                  background: linear-gradient(
                    110deg,
                    #95008e 0%,
                    #3d94fa 16%,
                    #04d8ff 80.31%
                  );
                  background-clip: text;
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  .claimable-rewards-unit {
                    font-family: Poppins;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    margin-left: 8px;
                    background: linear-gradient(
                      139deg,
                      #95008e -49.14%,
                      #3d94fa 48.38%,
                      #04d8ff 116.75%
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
                .claimable-rewards-button {
                  background-color: #4ccbc9;
                  padding: 3px 16px;
                  font-family: Poppins;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 30px;
                  color: #280748;
                  text-transform: capitalize;
                  border-radius: 8px;
                  &.disabled {
                    background-color: #7fa1a1;
                    color: #3d3544;
                  }
                }
              }
            }
            .go-to-stake {
              width: 100%;
              padding: 6px 10px;
              border-radius: 8px;
              background: linear-gradient(85deg, #e4007b 2.68%, #4ccbc9 100%);
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 36px;
              text-transform: capitalize;
              color: #fff;
              font-family: Poppins;
            }
          }
        }
        .astar-page-section-1-right {
          background-color: #21063c;
          position: relative;
          padding: 16px 8px;
          border-radius: 8.5px;
          width: 100%;
          &::before {
            content: "";
            position: absolute;
            top: -1.5px;
            right: -1.5px;
            bottom: -1.5px;
            left: -1.5px;
            background: linear-gradient(85deg, #e4007b 2.68%, #4ccbc9 100%);
            z-index: -1;
            border-radius: 10px;
            box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
          }
          .stake-benefit-img {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
            }
          }
          .stake-info {
            .stake-info-banner {
              display: flex;
              gap: 8px;
              flex-direction: column;
              margin-bottom: 22px;
              .stake-banner-item {
                width: 100%;
                &.first {
                  padding: 16px 16px 24px;
                  background-color: #351c4f;
                  border-radius: 8px;
                  .stake-banner-item-header {
                    color: #fff;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    text-transform: capitalize;
                    margin: 0 0 14px 0;
                  }
                  .stake-banner-item-number {
                    font-family: Poppins;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                    margin: 0 0 24px 0;
                    background: linear-gradient(
                      110deg,
                      #95008e 0%,
                      #3d94fa 22%,
                      #04d8ff 80.31%
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    .stake-banner-item-unit {
                      font-family: Poppins;
                      font-size: 24px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 32px;
                      background: linear-gradient(
                        139deg,
                        #95008e -49.14%,
                        #3d94fa 48.38%,
                        #04d8ff 116.75%
                      );
                      background-clip: text;
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                  .stake-banner-item-next {
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    color: #90829d;
                    text-transform: capitalize;
                    .stake-banner-item-next-time {
                      font-weight: 600;
                      color: #fff;
                    }
                  }
                }
                &.second {
                  background-color: #351c4f;
                  border-radius: 8px;
                  padding: 16px 16px 24px;
                  .stake-banner-item-bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 8px;
                    z-index: 0;
                    overflow: hidden;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  .stake-banner-item-content {
                    position: relative;
                    z-index: 1;
                    .stake-banner-item-header {
                      font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 24px;
                      color: #fff;
                      text-transform: capitalize;
                      margin: 0 0 14px 0;
                    }
                    .stake-banner-item-number {
                      color: #fff;
                      font-family: Poppins;
                      font-size: 40px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 40px;
                      margin: 0 0 16px;
                    }
                    .stake-banner-item-next {
                      color: #fff;
                      font-family: Poppins;
                      font-size: 20px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 36px;
                      margin: 0;
                    }
                  }
                }
              }
            }
            .stake-benefit {
              position: relative;
              padding: 0;
              border-radius: 10px;
              overflow: hidden;
              .stake-benefit-bg {
                display: none;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 0;
                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .stake-benefit-content {
                position: relative;
                z-index: 1;
                margin-bottom: 0;
                .stake-benefit-header {
                  color: #fff;
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
                  text-transform: capitalize;
                  margin: 0 0 12px;
                }
                .stake-benefit-list {
                  display: flex;
                  gap: 8px;
                  padding: 0;
                  margin: 0;
                  flex-direction: column;
                  margin-bottom: 158px;
                  .stake-benefit-item {
                    width: 100%;
                    padding: 16px;
                    background-color: rgba(161, 107, 216, 0.3);
                    border-radius: 10px;
                    margin: 0;
                    .stake-benefit-item-index-wrapper {
                      position: relative;
                      .stake-benefit-item-index {
                        display: block;
                        width: 32px;
                        height: 32px;
                        border-radius: 36px;
                        background-color: #e4007b;
                        color: #fff;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 32px;
                        text-align: center;
                        margin: 0 0 16px 0;
                        position: relative;
                        z-index: 10;
                      }
                      .border-index {
                        position: absolute;
                        content: "";
                        background-color: #502f70;
                        width: 32px;
                        height: 32px;
                        top: 0;
                        left: 0;
                        border-radius: 50%;

                        animation: scaleCircle infinite 1s alternate;
                      }
                    }

                    .stake-benefit-item-content {
                      color: #fff;
                      font-family: Poppins;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 28px;
                      text-transform: none;
                      .change-color-1 {
                        font-weight: 600;
                        color: #4dfff6;
                      }
                      .change-color-2 {
                        font-weight: 600;
                        color: #48c8f0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .astar-page-section-2 {
        margin-bottom: 32px;
        .astar-page-section-2-header {
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          text-transform: capitalize;
          margin: 0 0 45px;
        }
        .astar-guide-list {
          display: flex;
          gap: 44px;
          flex-direction: column;
          .astar-guide-item {
            width: 100%;
            height: fit-content;
            position: relative;

            .astar-guide-item-header {
              color: #fff;
              padding: 13px 43px;
              color: #fff;
              text-align: center;
              leading-trim: both;

              text-edge: cap;
              font-family: Poppins;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px; /* 100% */
              text-transform: uppercase;
              border-radius: 8px;
              background: #a16bd8;
              box-shadow: 0px 4px 0px 0px #7950a2;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              z-index: 2;
            }

            &.step-1 {
              &::before {
                content: "";
                position: absolute;
                top: -1.5px;
                bottom: -1.5px;
                left: -1.5px;
                right: -1.5px;
                border-radius: 10px;
                background: linear-gradient(90deg, #e4007b 0%, #4ccbc9 100%);
                box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
                z-index: -1;
              }
              .astar-guide-item-content {
                background-color: #21063c;
                position: relative;
                z-index: 1;
                padding: 43px 16px 24px;
                color: #90829d;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
                border-radius: 8.5px;
                text-transform: none;
              }
            }

            &.step-2 {
              &::before {
                content: "";
                position: absolute;
                top: -1.5px;
                bottom: -1.5px;
                left: -1.5px;
                right: -1.5px;
                border-radius: 10px;
                background: linear-gradient(90deg, #e4007b 0%, #4ccbc9 100%);
                box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
                z-index: -1;
              }

              .astar-guide-item-content {
                background-color: #21063c;
                position: relative;
                z-index: 1;
                top: 0;
                padding: 44px 16px 24px;
                color: #90829d;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
                border-radius: 8.5px;
                text-transform: none;
                .change-small-text {
                  margin-top: 8px;
                }
              }
            }

            &.step-3 {
              &::before {
                content: "";
                position: absolute;
                top: -1.5px;
                bottom: -1.5px;
                left: -1.5px;
                right: -1.5px;
                border-radius: 10px;
                background: linear-gradient(90deg, #e4007b 0%, #4ccbc9 100%);
                box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
                z-index: -1;
              }

              .astar-guide-item-content {
                background-color: #21063c;
                position: relative;
                z-index: 1;
                top: 0;
                padding: 44px 16px 23px;
                color: #90829d;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
                border-radius: 8.5px;
                text-transform: none;
                .change-color {
                  color: #4dfff6;
                }
              }
            }
          }
        }
        .astar-guide-note-wrapper {
          display: flex;
          flex-direction: column;
          gap: 32px;
          margin-top: 25px;
          align-items: flex-start;
          .astar-guide-note {
            max-width: 740px;
            padding: 16px;
            background-color: rgba(53, 28, 79, 0.7);
            border-radius: 10px;
            .astar-guide-note-header {
              color: #fff;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 28px;
              text-transform: none;
            }
            .astar-guide-note-list {
              margin-top: 16px;
              display: flex;
              flex-direction: column;
              gap: 16px;
              .astar-guide-note-item {
                display: flex;
                gap: 16px;
                align-items: flex-start;
                .astar-guide-note-item-icon {
                  margin-top: 0;
                  width: fit-content;
                  height: fit-content;
                  img {
                    width: 24px;
                    height: 24px;
                    max-width: none;
                    // object-fit: contain;
                  }
                }
                .astar-guide-note-item-content {
                  display: block;
                  color: #90829d;
                  font-family: Poppins;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  text-transform: none;
                }
              }
            }
          }
          .tutorial {
            color: #fff;
            text-transform: none;
            .tutorial-header {
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 28px;
              margin-bottom: 8px;
            }
            .tutorial-content {
              font-family: Poppins;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 36px;
            }
          }
        }
      }
    }

    .astar-page-section-2-wrapper {
      position: relative;
      .astar-page-section-2-bg {
        position: absolute;
        top: 0;
        left: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .astar-page-footer-wrapper {
      padding-top: 32px;
      padding-bottom: 100px;
      position: relative;
      .astar-page-footer-bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        img {
          width: 100%;
          height: 100%;
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: block;
          }
        }
      }
      .astar-page-footer-img {
        position: absolute;
        top: 62px;
        left: 0;
        display: none;
        img {
          width: 666px;
          height: 496px;
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: block;
          }
        }
      }
      .astar-page-footer {
        scale: 1;
        display: flex;
        flex-direction: column;
        color: #fff;
        max-width: 1170px;
        margin: 0 auto;
        gap: 0;
        padding: 0 16px;
        .astar-page-footer-left {
          width: 100%;
          h4 {
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
            text-transform: none;
          }
          img {
            display: block;
          }
        }
        .astar-page-footer-right {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 16px;
          .info-list {
            display: flex;
            flex-direction: column;
            .info-item {
              display: flex;
              gap: 8px;
              .icon {
                // margin-top: 4px;
                img {
                  width: 24px;
                  max-width: none;
                  object-fit: contain;
                }
              }
            }
          }
          .divide-y {
            // padding: 16px;
            background-color: #351c4f;
            border: none;
            .px-5 {
              border-color: rgba(237, 237, 237, 0.1);
              text-transform: none;
              margin: 0;
              padding: 17px 16px 16px 16px !important;
              .qa-content {
                color: #90829d;
                font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                &:not(:last-child) {
                  margin-bottom: 16px;
                }
              }
            }
            button {
              height: fit-content;
              background-color: #351c4f;
              color: #90829d;
              padding: 16px !important;
              &.text-gray-900 {
                padding: 16px 16px 16px 16px !important;
                color: #fff;
                h2 {
                  color: inherit;
                }
              }

              &:focus {
                --tw-ring-opacity: 0;
              }
              &:hover {
                background-color: unset;
              }
              h2 {
                font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                text-transform: none;
                color: inherit;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:375px){
  .share-rewards-modal {
    width: 771px !important;
    .ant-modal-content {
      border-radius: 17px;
      background-color: #21063c;

      &::before {
        position: absolute;
        z-index: -1;
        content: "";
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;
        border-radius: 20px;
        background: linear-gradient(90deg, #e4007b 0%, #4ccbc9 100%);
        box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);
      }
      .ant-modal-close {
        display: none;
      }
      .ant-modal-body {
        padding: 24px 8px;
        .close-btn {
          position: absolute;
          top: 8px;
          right: 8px;
          width: 20px;
          height: 20px;
          bottom: unset;
          left: unset;
          transform: translateX(0);
          &:active {
            transform: translateX(0) translateY(5px);
          }
          img {
            width: 40px;
            height: 40px;
            &:nth-child(1) {
              display: none;
            }
            &:nth-child(2) {
              width: 20px;
              height: 20px;
              display: block;
            }
          }
        }
        .share-rewards-modal-header {
          color: #fff;
          text-align: center;
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px;
          margin-bottom: 6px;
        }
        p {
          color: #fff;
          text-align: center;
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 21px;
          margin: 0;
        }
        .share-rewards-img {
          position: relative;
          img {
            &:nth-child(2) {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              width: 109px;
            }
          }
        }
        .benefit-list {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 0;
          margin: 16px 0 24px 0;
          .benefit-item {
            padding: 10px 16px;
            background-color: #351c4f;
            border-radius: 80px;
            display: flex;
            gap: 16px;
            align-items: center;
            width: 100%;
            .index {
              position: relative;
              span {
                width: 28px;
                height: 28px;
                color: #fff;
                background-color: #e4007b;
                border-radius: 50%;
                display: inline-block;
                text-align: center;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                position: relative;
                z-index: 1;
              }
              .border-index {
                position: absolute;
                z-index: 0;
                content: "";
                background-color: #502f70;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 50%;
                animation: scaleCircle infinite 3s alternate;
              }
            }
            .text {
              color: #90829d;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
            }
          }
        }
        .button-wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;
          button {
            &:active {
              transform: translateY(5px) !important;
            }
            &:hover {
              transform: translateY(0);
            }
            &:focus {
              transform: translateY(0);
            }
          }
          .share {
            padding: 9px 24px;
            border-radius: 8px;
            background: linear-gradient(85deg, #e4007b 2.68%, #4ccbc9 100%);
            color: #fff;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }
          .stake {
            padding: 9px 24px;
            border-radius: 8px;
            background: linear-gradient(
              111deg,
              #95008e 6.93%,
              #3d94fa 51.18%,
              #04d8ff 115.93%
            );
            color: #fff;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }
        }
      }
    }
  }

}