button,
input[type='button'],
input[type='reset'],
input[type='submit'],
.button {
  @include NormalTransition();
  --button-hoz-padding: 18px;
  padding: 0 var(--button-hoz-padding) 2px;
  min-height: 44px;
  line-height: 22px;
  outline: none;
  text-decoration: none;
  color: var(--mg-button-text, $button-text);
  //border: 2px solid var(--mg-button-border, $button-border);
  background-color: var(--mg-button-background, $button-background);
  border-radius: $button-rounded;
  font-family: $button-font-family;
  font-size: 16px;
  font-weight: $button-font-weight;
  letter-spacing: $button-letter-spacing;
  text-transform: $button-text-transform;
  //cursor: pointer;
  text-align: center;
  user-select: none;
  box-shadow: none;

  &:focus {
    outline: none;
  }

  //&[disabled],
  //&.disabled {
  //  cursor: not-allowed;
  //  background-color: $button-background !important;
  //  border-color: $button-border !important;
  //  color: $button-text !important;
  //  box-shadow: none !important;
  //}

  &:focus,
  &:hover {
    transform: translate(0px, -2px);
    //color: var(--mg-button-hover-text, $button-hover-text);
    //border-color: var(--mg-button-hover-border, $button-hover-border);
    //background-color: var(--mg-button-hover-background, $button-hover-background);
    //box-shadow: 0 0 0 0.2rem var(--mg-button-hover-background, $button-hover-background);
  }
}

.button-icon {
  flex-shrink: 0;
  line-height: 1;
  margin: 0 8px 0 0;

  svg {
    display: block;

    *[stroke^="#"],
    *[stroke^="url(#"] {
      stroke: currentColor;
    }

    *[fill^="#"],
    *[fill^="url(#"] {
      fill: currentColor;
    }
  }
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.button-size-sm {
    --button-hoz-padding: 15px;
    min-height: 45px;
    line-height: 26px;
    font-size: 18px;
  }

  .button-text {
    display: flex;
    justify-content: center;
    align-items: center;

    &.text-normal {
      text-transform: none;
    }
  }

  &.icon-right {
    .button-icon {
      margin: 0 0 0 8px;
    }
  }

  .loading {
    width: 25px;
    height: 25px;
    border: 4px solid #fff;
    border-radius: 100%;
  }

  .open {
    border-top: 5px solid transparent;
    -webkit-animation: load-animate infinite linear 1s;
    animation: load-animate infinite linear 1s;
  }

  + button {
    margin-left: 15px;
  }
}

.button-image {
  img {
    @extend %NormalTransition;
  }

  &:hover {
    img {
      transform: scale3d(1.05, 1.05, 1.05);
    }
  }
}

.button-group {
  --gutter-x: 30px;
  --gutter-y: 10px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(-0.5 * var(--gutter-x));

  .button,
  .button-image {
    margin: var(--gutter-y) calc(0.5 * var(--gutter-x)) 0;
  }
}

.button-primary {
  --mg-button-text: #{$button-text};
  --mg-button-border: #{$primary-color};
  --mg-button-background: #{$primary-color};
}

.button-secondary {
  --mg-button-text: #fff;
  --mg-button-border: #{$secondary-color};
  --mg-button-background: #{$secondary-color};

  &[disabled],
  &.disabled {
    &:hover {
      transform: none !important;
    }

    cursor: not-allowed;
    background-color: #e4007b9e !important;
    border-color: $secondary-color !important;
    color: #FFFFFF !important;
    box-shadow: none !important;
    opacity: 0.7;
  }
}

.button-twitter {
  --mg-button-text: #fff;
  --mg-button-border: #{$twitter-color};
  --mg-button-background: #{$twitter-color};

  &:hover, &:focus {
    color: #fff;
  }
  &[disabled],
  &.disabled {
    &:hover {
      transform: none !important;
    }

    cursor: not-allowed;
    background-color: #14426e !important;
    border-color: $twitter-color !important;
    color: #FFFFFF !important;
    box-shadow: none !important;
  }
}

.button-subwallet {
  --mg-button-text: #fff;
  --mg-button-border: #004bff;
  --mg-button-background: #004bff;
  --mg-button-hover-text: #fff;
  --mg-button-hover-border: #004bff;
  --mg-button-hover-background: #004bff;
  font-family: Darker Grotesque, sans-serif;
  font-weight: 600;
  font-size: 18px;
  //min-height: 56px;
  text-transform: none;
  padding: 0 var(--button-hoz-padding) 5px;
}

.button-dark {
  --mg-button-text: #fff;
  --mg-button-hover-text: #fff;
  padding: 0 var(--button-hoz-padding);
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.2);

}

@include media-breakpoint-up(md) {
  .button {
    &.button-size-lg {
      --button-hoz-padding: 35px;
      line-height: 26px;
      min-height: 55px;
    }
  }
}

@-webkit-keyframes load-animate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
    opacity: 0.35;
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes load-animate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
    opacity: 0.35;
  }
  100% {
    transform: rotate(360deg);
  }
}