.section-hero {
	z-index: 1;
	padding: 280px 0 281px;
	
	&.attachment-bg {
		background-image: url(../../assets/images/nft-sale-bg.png);
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
	}

	&.non-attachment-bg {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-image: url(../../assets/images/background.png), url(../../assets/images/background-footer.png);
			background-repeat: no-repeat, no-repeat;
			background-size: auto, auto;
			background-position: top, bottom;
			opacity: 0.7;
		}
	}

	&.section-hero-3d-bg {
		background: url(../../assets/images/nft-sale-bg-2x-tinied.png) no-repeat center;
		background-size: cover;
	}

	.button-group {
		--gutter-x: 20px;
		margin-top: 57px;
	}

	.button {
		--button-hoz-padding: 15px;
		min-width: 180px;
	}

	.section-header {
		margin: 0;
	}

	.hero-image {
		margin: 0 0 21px;

		img {
			max-width: 30vw;
		}
	}

	.section-header-inner {
		max-width: 874px;
	}

	.section-title {
		font-size: 40px;
		line-height: 1.15;

		mark {
			color: $secondary-color;
		}
	}

	.section-description-wrap {
		margin: 9px 0 0;
	}

	.section-description {
		text-transform: none;
		line-height: 1.3;
		font-weight: 700;
		font-size: 40px;
		color: #fff;
	}

	.section-shape-polkadot {
		right: 7%;
		bottom: 32%;

		img {
			max-width: 6vw;
		}
	}

	.section-shape-acala {
		right: 14%;
		top: 20%;

		img {
			max-width: 9vw;
		}
	}

	.section-shape-kusama {
		top: 23%;
		left: 5.3%;

		img {
			max-width: 6vw;
		}
	}

	.section-shape-moonbeam {
		bottom: 32%;
		left: 19%;

		img {
			max-width: 7vw;
		}
	}

	.section-shape-rmrk {
		bottom: 10%;
		left: 5%;

		img {
			max-width: 8vw;
		}
	}
}

.section-hero-shape-meteor {
	bottom: 70px;
	left: 50%;
	transform: translateX(-50%);
}

@media screen and (max-width: 1440px) {
	.section-hero {
		padding: 200px 0;

		.section-title {
			font-size: 36px;
		}

		.section-description {
			font-size: 36px;
		}
	}
}

@include media-breakpoint-down(md) {
	.section-hero {
		padding: 150px 0;

		.section-title {
			font-size: 32px;
		}

		.section-description {
			font-size: 32px;
		}

		.button-group {
			margin-top: 30px;
		}
	}

	.section-hero-shape-meteor {
		bottom: 40px;
	}
}

@media screen and (max-width: 1536px) {
	.section-hero {
		.button {
			min-width: 160px;
		}
	}
}

@include media-breakpoint-down(sm) {
	.section-hero {
		padding: 120px 0;

		.button-group {
			margin-top: 20px;
		}

		.button {
			min-width: 150px;
		}

		.section-title {
			font-size: 28px;
		}

		.section-description {
			font-size: 28px;
		}

		.hero-image img {
			max-width: 35vw;
		}
	}

	.section-hero-shape-meteor {
		bottom: 0;
	}
}

@include media-breakpoint-down(xs) {
	.section-hero {
		.section-description {
			font-size: 24px;
			line-height: 28px;
		}

		.button-group {
			--gutter-x: 14px;
		}
	}
}
