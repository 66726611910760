.moonfit-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 39px auto 0;

  .moonfit-card-inner {
    //background-color: #020722;
    background-color: #1c0532;
    width: 100%;
    padding: 39px 30px 30px 30px;
    color: #fff;
    position: relative;
    background-clip: padding-box;
    border: solid 3px transparent;
    border-radius: 20px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -2px;
      border-radius: inherit;
      background: linear-gradient(125deg, #4CCBC9 0%, #E4007B 100%);
    }

    .section-inner {
      width: 100%;
      border-radius: 7px;
      background-color: #2A1143;

      &.from{
        .from-value{
          text-transform: none;
          font-size: 24px;
          line-height: 30px;
          font-weight: 600;
        }
      }

      &.to{
        .to-value{
          text-transform: none;
          font-size: 24px;
          line-height: 30px;
          font-weight: 600;
        }
      }

      .network{
        .network-info{
          height: 50px;
          border-radius: 5px;
          line-height: 50px;
          background: #1C0532;
          display: flex;
          align-items: center;
          padding: 14px 16px 12px;

          img{
            margin-right: 8px;
            width: 24px;
          }
        }
      }
      
      .amount{
        .amount-form{
          position: relative;

          .amount-input{
            .ant-input-number-handler-wrap{
              display: none;
            }
          }

          .max-value{
            position: absolute;
            top: 10px;
            right: 16px;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
            text-align: right;
            color: #4CCBC9;
            text-transform: none;

            &:hover{
              cursor: pointer;
            }
          }
        }

        .balance{
          font-size: 16px;
          font-weight: 600;
          line-height: 28px;
          text-align: right;
          color: #fff;
          text-transform: none;
          height: 100%;
          margin-top: -1px;
          label{
            
            color: #A8ADC3;
          }
        }
        .fee{
          font-size: 16px;
          color: #A8ADC3;
          span{
            text-transform: none;
            &:nth-child(2){
              color: #4CCBC9;
             
            }
          }
        }
      }
    }

    .card-title {
      font-family: 'Race Sport', serif;
      color: #4ccbc9;
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 18px;

      .moonfit-card-title{
        font-size: 20px;
        line-height: 26px;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        text-align: left;

        .back-deposit{
          display: flex;
          font-size: 18px;
          line-height: 28px;
          font-weight: 800;
          color: #4CCBC9;
          font-family: "Darker Grotesque", sans-serif;

          svg{
            margin-top: 7px;
            margin-right: 6px;
          }

          &:hover{
            cursor: pointer;
          }
        }
      }
    }

    .card-body {
      padding: 0;
      max-width: 100%;
      margin: 0 auto;
      text-align: left;

      .card-body-row {
        padding: 16px 22px;
        background-color: #2A1143;
        border-radius: 7px;

        .card-body-row-title {
          color: #A8ADC3;
          font-size: 16px;
        }

        .card-body-separator {
          color: #90829d;
          opacity: 0.2;
          //border: 1px solid #90829D;
          margin: 20px 0 3px;
        }
      }

      .section-connected-wallet{
        .section-connected-massage{
          padding: 18px 20px 20px;
          background: #2A1143;
          border-radius: 7px;
          margin-bottom: 32px;

          p{
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
            text-transform: none;
            color: #ffffff;
          }
        }

        .section-connected-guide{
          .guide-title{
            text-align: center;
            margin: 0 0 20px;

            img{
              margin: 0 auto 10px;
            }

            p{
              font-size: 18px;
              font-weight: 600;
              line-height: 28px;
              text-transform: none;
              color: #fff;
            }
          }

          .guide-steps{
            .step{
              background: #2A1143;
              border-radius: 7px;
              padding: 8px 14px 0 18px;
              text-align: center;
              margin: 0 0 20px;
              display: flex;
              flex-direction: column;

              span{
                font-size: 18px;
                font-weight: 600;
                line-height: 28px;
                text-transform: none;
                color: #fff;
                margin-bottom: 8px;
              }

              .step-image{
                text-align: right;
                padding-right: 10px;

                img{
                  display: inline-block
                }
              }

              &.step-2{
                flex-direction: column-reverse;
                padding: 0px 18px 14px 14px;

                span{
                  margin-bottom: 2px;
                }

                .step-image{
                  text-align: left;
                  padding-right: 0;
                  padding-left: 10px;
                }
              }

              &.step-4{
                flex-direction: column-reverse;
                padding: 0px 18px 14px 14px;

                span{
                  margin-bottom: 0;
                  margin-top: 12px;
                }

                .step-image{
                  text-align: left;
                  padding-right: 0;
                  padding-left: 10px;
                }
              }
            }
          }
        }
      }

      .deposit-button{
        display: flex;
        margin: 20px -4px 0;

        button{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #4CCBC9;
          font-size: 18px;
          font-weight: 800;
          color: #020722;
          line-height: 50px;
          height: 50px;
          border: none;
          box-shadow: none;
          border-radius: 5px;
          margin: 0 4px;

          svg{
            margin-right: 4px;
          }

          &:disabled{
            background: #386884;
          }

          &.back-deposit{
            border: 2px solid rgba(255, 255, 255, 0.14);
            background: none;
            color: #ffffff;
          }
        }

        @media (max-width: 500px){
          flex-direction: column-reverse;

          button{
            margin-bottom: 6px;
          }
        }
      }

      .section-choose-account{
        // margin-bottom: 20px;

        .ant-radio-group{
          display: block;

          .ant-radio-wrapper{
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            background: #2A1143;
            margin: 0 0 10px;
            padding: 10px 12px;
            border-radius: 10px;

            .ant-radio{
              .ant-radio-input{
                position: absolute;
                z-index: 1;
                cursor: pointer;
                opacity: 0;
              }

              .ant-radio-inner{
                width: 24px;
                height: 24px;
                background: none;
                border-color: #7A678C;

                &::after{
                  background-color: #4CCBC9;
                  width: 22px;
                  height: 22px;
                  margin-left: -11px;
                  margin-top: -11px;
                }
              }

              &.ant-radio-checked:after{
                content: none;
              }
            }

            span.ant-radio+* {
              flex-grow: 1;

              .Radio-text{
                color: #fff;
                display: flex;
                align-items: center;

                .account-image{
                  width: 48px;
                  height: 48px;
                  position: relative;
                  padding: 2px;
                  border-radius: 50%;
                  background: linear-gradient(125deg, #4CCBC9 0%, #E4007B 100%);
                  margin-right: 10px;

                  .account-image-box{
                    background-color: #351C4F;
                    border-radius: 50%;
                    padding: 6px;

                    img{
                      border-radius: 50%;
                    }
                  }
                }

                .account-info{
                  span{
                    display: block;
                    text-transform: none;

                    &.account-name{
                      font-size: 18px;
                      font-weight: 700;
                      line-height: 22px;
                      color: #ffffff;
                    }

                    &.account-email{
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 22px;
                      color: #A8ADC3;
                    }
                  }
                }
              }
            }

            &:hover,
            &.ant-radio-wrapper-checked{
              background: #351C4F;

              .ant-radio{
                .ant-radio-inner{
                  border-color: #4CCBC9;
                }
              }
            }
          }
        }

        button.confirm{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          background: #4CCBC9;
          border-radius: 5px;
          border: none;
          margin-top: 20px;
          height: 50px;

          .confirm-icon{
            width: 20px;
            height: 20px;
            background: rgba(40,7,72,0.2);
            text-align: center;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 6px;
          }

          .confirm-text{
            font-size: 18px;
            font-weight: 800;
            line-height: 24px;
            color: #020722;
            padding-bottom: 4px;
          }
        }
      }

      .section-info{
        padding: 21px 22px 19px;

        .avatar{
          width: 96px;
          height: 96px;
          position: relative;
          padding: 2px;
          border-radius: 50%;
          background: linear-gradient(125deg, #4CCBC9 0%, #E4007B 100%);
          margin-right: 19px;
  
          .avatar-box{
            background-color: #351C4F;
            border-radius: 50%;
            padding: 4px;
          }
        }

        .info{
          p{
            font-size: 18px;
            font-weight: 600;
            color: #ffffff;
            line-height: 28px;
            text-transform: none;
            margin: 0;
            padding: 0;
          }
        }

        .button-change{
          position: absolute;
          top: 12px;
          right: 12px;
        }

        @media (max-width: 767px){
          flex-direction: column;
          align-items: start;
        }
      }

      .section-tokens{
        padding: 18px 20px 2px 20px;

        .tokens-title{
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          color: #A8ADC3;
          text-transform: uppercase;
          margin-bottom: 4px;
        }

        ul.token-list{
          margin: 0;
          padding: 0;
          min-height: 80px;

          li.token-item{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 16px 0;
            margin: 0;
            border-bottom: 1px solid rgba(144, 130, 157, 0.2);
            // &.ASTR{
            //   height: 0;
            //   width: 0;
            //   overflow: hidden;
            //   padding:0;
            //   border: 0;
            // }
            .token-info{
              display: flex;
              align-items: center;

              .token-image{
                width: 28px;
                height: 28px;
                border-radius: 50%;
                margin-right: 8px;
                overflow: hidden;

                img{
                  max-width: 100%;
                }
              }
            }

            &:last-child{
              border: none;
            }

            &:hover{
              cursor: pointer;

              &.MFG{
                color: #4CCBC9
              }

              &.GLMR{
                color: #E4007B
              }

              &.ASTR{
                color: #48C8F0;
              }

              &.BNB{
                color: #F3BA2F
              }

              &.MANTA_ETH{
                color:#d6cfff
              }
            }
          }
        }
      }

      .section-nfts{
        padding: 18px 20px 20px;

        .nft-title{
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          color: #A8ADC3;
          text-transform: uppercase;
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
        }

        .nft-nodata{
          text-align: center;
          display: flex;
          flex-direction: column;
          align-content: center;
          align-items: center;

          svg{
            margin: 0 auto 16px;
          }

          span{
            font-size: 18px;
            line-height: 28px;
            font-weight: 700;
            color: #fff;
          }

          .buy-nft{
            background: #4CCBC9;
            border-radius: 5px;
            width: 250px;
            height: 50px;
            line-height: 50px;
            color: #020722;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 16px;

            svg{
              margin: 0 6px 0 0;
            }

            &:hover{
              background-color: darken(#4CCBC9, 15%);
            }
          }
        }

        .nft-list{
          // margin: 0;
          // padding :0;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 20px;
          row-gap: 20px;

          .nft-list-item{
            // margin: 0;
            // padding: 0;

            .nft-image{
              padding: 2px;
              border-radius: 5px;
              background: none; // linear-gradient(125deg, #4CCBC9 0%, #E4007B 100%)
              position: relative;
              box-shadow: 0px 4px 30px 0px rgba(228, 0, 123, 0); // 0.5
              transition: all 0.6s ease 0s;

              .nft-image-box{
                display: block;
                padding: 5px;
                background: rgba(28, 5, 50, 0);
                border-radius: 5px;
                transition: all 0.6s ease 0s;

                img{
                  border-radius: 5px;
                }
              }

              .nft-tag{
                font-size: 14px;
                line-height: 18px;
                font-weight: 700;
                color: #FFFFFF;
                padding: 2px 6px 4px;
                text-transform: none;
                position: absolute;
                bottom: 10px;
                left: 11px;
                display: inline-block;
                background: #48C8F0;
                border-radius: 5px;
              &.common{
                background: #EFAA5D;
              }
              }

              .nft-mask{
                position: absolute;
                top: 11px;
                right: 11px;
                width: 28px;
                height: 28px;
              }
            }

            .nft-stats{
              margin: 2px 0 0;
              padding:0 7px;

              .nft-stats-box{
                background: rgba(161, 107, 216, 0.15);
                border-radius: 5px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                padding: 8px 0 5px;
              }
              
              .stat-item{
                border-right: 1px solid rgba(144, 130, 157, 0.2);

                &:last-child{
                  border: none;
                }

                .stat-item-box{
                  text-align: center;

                  .stat-icon{
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    margin: 0 auto;
                    background: #A16BD8;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.running{
                      background: #EFAA5D;
                    }

                    &.gift{
                      background: #F0489F;
                    }

                    &.speed{
                      background: #A5D990;
                    }
                  }

                  .stat-value{
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 600;
                    color: #fff;
                    display: block;
                  }
                }
              }
            }

            .nft-name{
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              color: #E4007B;
              text-align: center;
              margin-top: 13px;

              .code{
                display: block;
                color: #4CCBC9;
              }
            }

            &:hover{
              cursor: pointer;

              .nft-image{
                background: linear-gradient(125deg, #4CCBC9 0%, #E4007B 100%);
                box-shadow: 0px 4px 30px 0px rgba(228, 0, 123, 0.5);
  
                .nft-image-box{
                  background: rgba(28, 5, 50, 1);
                }
              }
            }
          }

          @media (max-width: 767px){
            column-gap: 3px;
            row-gap: 3px;
            grid-template-columns: 1fr 1fr;
          }
        }
      }

      .mf-address-change{
        @media (max-width: 767px) {
          flex-direction: column;
          
          .section-inner{
            margin-bottom: 10px;
          }
        }
      }

      // .deposit-popup-overlay{
      //   position: fixed;
      //   top:0;
      //   left:0;
      //   right:0;
      //   bottom:0;
      //   background-color: black;
      //   z-index: 99;
      //   opacity: 0.7;
      // }

      .deposit-popup{
        display:none;
        position: fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-color: rgba(0, 0, 0, 0.788);
        z-index: 9999;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:24px;
        
        &.active{
          display:flex;
        }

        .deposit-popup-close{
          width: 32px;
          height: 32px;
          cursor: pointer;
        }


        .deposit-popup-content{
          position: relative;
          display:flex;
          flex-direction: column;
          align-items: center;
          gap:24px;
          padding:30px;
          background-clip: padding-box;
          border: 3px solid transparent;
          background:  #351C4F;
          border-radius: 20px;
          box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.60);
          text-transform: initial;
          @media (max-width:375px) {
            max-width: 90%;
            padding:20px;
            gap:16px
          }
          @media (max-width:767px) {
            max-width: 90%;
            padding:25px;
            gap:20px
          }
          &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -6px; /* same as border width */
            border-radius: 21.5px; /* inherit container box's radius */
            background: linear-gradient(to left, #4ccbc9, #F0489F);
          }


          .deposit-text-header{
            text-align: center;
            color: #FFF;
            text-align: center;
            font-family: Race Sport;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            text-transform: uppercase;
            @media (max-width:375px) {
             font-size: 16px;
            }
            @media (max-width:767px) {
              font-size: 18px;
            }
          }

          .deposit-popup-icon{
            width: 88px;
            height: 88px;

            @media (max-width:375px) {
              width: 78px;
            height: 78px;
             }
             @media (max-width:767px) {
              width: 83px;
              height: 83px;
            }
          }

          .small-text{
            width: 370px;
            color: #A8ADC3;
            text-align: center;
            font-family: 'Darker Grotesque',sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            @media (max-width:767px) {
              // width: 250px;
              max-width: 90%;
            }
          }

          .agree-button{
            cursor: pointer;
            display:flex;
            justify-content: center;
            align-items: center;
            width: 370px;
            height: 48px;
            border-radius: 7px;
            background:  #4CCBC9;
            gap:6px;
            text-transform: lowercase;
            font-size: 18px;
            @media (max-width:767px) {
            //  width: 250px;
             max-width: 90%;
             font-size: 16px;
            }
            img{
              width: 20px;
              height: 20px;
              @media (max-width:375px) {
               width: 16px;
               height: 16px;
               }
            }
            span{
              color: #020722;
              font-family: "Darker Grotesque", sans-serif;
              
              font-style: normal;
              font-weight: 800;
              line-height: normal;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    &.worldcup-card-inner {
      position: relative;
      background-clip: padding-box;
      border: solid 2px transparent;
      border-radius: 10px;
      background-color: #000000;
      box-shadow: 0px 0px 20px 0px rgb(102 66 79 / 50%);
      -webkit-box-shadow: 0px 0px 20px 0px rgb(102 66 79 / 50%);
      -moz-box-shadow: 0px 0px 20px 0px rgb(102 66 79 / 50%);
      .card-body-row {
        background-color: #220510;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -3px;
        border-radius: inherit;
        background: linear-gradient(to right bottom, #3d0516, #4e0c20, #60132a, #731b35, #862341);
      }
    }
  }
}

.css-5km53n-control{
  .css-9ww52q-IndicatorsContainer{
    position: absolute;
    top: 0;
    right: 0;
    background: #1C0532;
  }
}

.menuList{
  margin-top: -5px;
  padding: 1px;
  border-radius: 7px;
  background: linear-gradient(125deg, #4CCBC9 0%, #E4007B 100%);

  .menuListBox{
    background-color: #351C4F;
    border-radius: 7px;
    padding: 0 0 10px;
    max-height: 450px;
    overflow-y: auto;
  }
}

.loading-icon{
  font-size: 20px !important;
  margin-right: 10px;
  color: #000000;
}

.mf-modal-confirm{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .mf-modal-confirm-box{
    width: 470px;
    padding: 3px;
    border-radius: 20px;
    background: linear-gradient(125deg, #4CCBC9 0%, #E4007B 100%);
    margin: 0;
    box-shadow: 0px 4px 80px 0px rgba(161, 107, 216, 0.6);

    .mf-modal-confirm-content{
      background-color: #351C4F;
      border-radius: 20px;
      padding: 24px 30px 30px;
      text-align: center;

      .confirm-text{
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        color: #ffffff;
        text-transform: none;
        margin: 0 0 27px;
        padding: 0 15px;

        span{
          color: #4CCBC9;
          word-wrap: break-word;
        }
      }

      .confirm-button{
        .confirm-no{
          border: 2px solid rgba(255, 255, 255, 0.14);
          height: 50px;
          line-height: 46px;
          width: 180px;
          text-align: center;
          font-size: 18px;
          font-weight: 800;
          color: #fff;
          padding: 0;
          border-radius: 5px;
          transition: all 0.3s ease 0s;
          margin: 0 8px;

          &:hover{
            border-color: #E4007B;
            background-color: #E4007B;
          }
        }

        .confirm-yes{
          height: 50px;
          background-color: #E4007B;
          line-height: 50px;
          width: 180px;
          text-align: center;
          font-size: 18px;
          font-weight: 800;
          color: #fff;
          padding: 0;
          border-radius: 5px;
          transition: all 0.3s ease 0s;
          border: none;
          margin: 0 8px;

          &:hover{
            background-color: darken(#E4007B, 15%);
          }
        }

        .confirm-done{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          background-color: #4CCBC9;
          line-height: 50px;
          width: 100%;
          text-align: center;
          font-size: 18px;
          font-weight: 800;
          color: #020722;
          padding: 0;
          border-radius: 5px;
          transition: all 0.3s ease 0s;
          border: none;
          margin: 0 0 20px;

          &:hover{
            background-color: darken(#4CCBC9, 15%);
          }

          .icon{
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: rgba(2, 7, 34, 0.2);
            margin-right: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .view-result{
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: 800;
          line-height: 24px;
          color: #A16BD8;

          svg{
            margin-right: 6px;
          }
        }
      }

      .mf-result-submitted{
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        color: #ffffff;
        margin: 0 0 32px;
      }

      .mf-result-message{
        display: block;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: #A8ADC3;
        text-transform: none;
        margin: 0 0 24px;
      }

      .mf-result-image{
        img{
          margin: 0 auto 32px;
        }
      }
    }

    @media (max-width: 767px){
      width: 325px;

      .mf-modal-confirm-content{
        padding: 15px;
        
        .confirm-text{
          padding: 0;
        }
  
        .confirm-button{
          .confirm-no{
            margin: 0 0 10px;
          }
        }
      }
    }
  }

  &.result{
    .mf-modal-confirm-box{
      width: 430px;

      @media (max-width: 767px){
        width: 325px;
      }
    }
  }
}

.ant-tooltip{
  max-width: 350px;

  .ant-tooltip-inner{
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .moonfit-card {
    .moonfit-card-inner {
      padding: 15px;
    }
  }
}