.stage {
    background-color: #1C0532;
    border-radius: 10px;
    padding: 30px 20px 20px 20px;
    position: relative;
    background-clip: padding-box;
    border: solid 2px transparent;
    width: 290px;
    min-height: 350px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -2px;
      border-radius: inherit;
      background: linear-gradient(to right, #4ccbc9, #e4007b);
    }
    &.sold-out {
        // filter: brightness(75%);
        .stage-content {
            opacity: 0.5;
        }
        &::before {
            background: #1C0532;
        }
    }
    .button {
        &:hover, &:focus {
            color: #ffffff;
        }
    }
    .badge {
        position: absolute;
        left: -5px;
        top: 5px;
        width: 100px;
        font-size: 16px;
        &::after {
            content: "";
            height: 16px;
            width: 16px;
            position: absolute;
            background-color: #1C0532;
            top: 12%;
            right: -9px;
            border-top: 1px solid #1C0532;
            border-left: 1px solid #1C0532;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
    }
    h1 {
        margin: 0 0 -10px 0;
        color: #E4007B;
    }
    h2 {
        font-size: 24px;
        color: #E4007B;
    }
    h3 {
        font-size: 20px;
        color: #E4007B;
    }
    h4 {
        font-size: 18px;
        color: #4CCBC9;
    }
    .arrow-right {
        margin-right: 5px;
    }
    .ic-moonbeam,
    .ic-mintpass {
        margin: 0 5px;
    }
    .ic-plus {
        display: inline-block;
        margin-left: 5px;
    }
    .description {
        font-weight: 700;
        font-size: 13px;
    }
    .ant-progress-inner {
        background-color: #462368;
    }
    .mint-sold {
        color: #4CCBC9;
        font-size: 14px;
    }
    .button {
        // margin-top: 15px;
        width: 100%;
    }
}
@media (max-width: 768px) {
    .stage-title {
        z-index: 2;
        text-align: center;
        font-size: 28px;
    }
}
